export class CentreRequest {

  nomCentre: string;
  adRue1: string;
  adCp: string;
  adVille: string;
  telephone: string;
  mail: string;
  centreType: string;
  applicationId: number;
  centreLabel: number;

  arsId?: number;
  centreLabelAnnee?: number;
  adRue2?: string;
  finessJuridique?: string;
  finessGeographique?: string;
  numAdeli?: string;
  centreParentId?: number;

  constructor(centreDetails: CentreDetails,
              public isLiberal: boolean) {
    this.nomCentre = centreDetails.nomCentre;
    this.adRue1 = centreDetails.adRue1;
    if (centreDetails.adRue2) {
      this.adRue2 = centreDetails.adRue2;
    }
    this.adCp = centreDetails.adCp;
    this.adVille = centreDetails.adVille;
    this.telephone = centreDetails.telephone;
    this.mail = centreDetails.mail;
    this.centreType = centreDetails.centreType;
    this.applicationId = centreDetails.applicationId;
    this.centreLabel = centreDetails.centreLabel;

    if (this.centreLabel) {
      this.centreLabelAnnee = centreDetails.centreLabelAnnee;
    }

    if (!this.isLiberal) {
      this.finessJuridique = centreDetails.finessJuridique;
      this.finessGeographique = centreDetails.finessGeographique;
      this.centreParentId = centreDetails.centreParentId;
    } else {
      this.numAdeli = centreDetails.numAdeli;
    }
    if (centreDetails.arsId) {
      this.arsId = centreDetails.arsId;
    }
  }
}

export class SerializedCentreRequest {

}

export type CentreDetails = {
  nomCentre: string;
  adRue1: string;
  adCp: string;
  adVille: string;
  telephone: string;
  mail: string;
  centreType: string;
  applicationId: number;
  centreLabel: number;

  arsId?: number;
  centreLabelAnnee?: number;
  adRue2?: string;
  finessJuridique?: string;
  finessGeographique?: string;
  numAdeli?: string;
  centreParentId?: number;
}

