import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable, shareReplay, switchMap } from 'rxjs';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import {
  selectAccessRequest,
  selectArsLabelById,
  selectCentreLabelById,
  selectUserTypeLabelForUniqueKey
} from '@access-request-unicorn/state/app.selectors';
import { AccessRequest } from '@access-request-unicorn/models/access-request.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';

@Component({
  selector: 'access-request-summary-user-info',
  templateUrl: './summary-user-info.component.html',
  styleUrls: ['./summary-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryUserInfoComponent implements OnInit {
  config$!: Observable<DisplayTextConfig>;
  accessRequest$!: Observable<AccessRequest>;
  userTypeLabel$!: Observable<string>;
  arsLabel$!: Observable<string>;
  centreLabel$!: Observable<string>;

  constructor(private store: Store,
              private config: ConfigFacade) {}

  ngOnInit() {
    this.config$ = this.config.displayText$;
    this.accessRequest$ = this.store.select(selectAccessRequest).pipe(
      shareReplay(1)
    );
    this.userTypeLabel$ = this.accessRequest$.pipe(
      switchMap(accessRequest => this.store.select(selectUserTypeLabelForUniqueKey(accessRequest.userType)))
    );
    this.arsLabel$ = this.accessRequest$.pipe(
      filter(accessRequest => !!accessRequest.arsId),
      switchMap(accessRequest => this.store.select(selectArsLabelById(accessRequest.arsId!)))
    );
    this.centreLabel$ = this.accessRequest$.pipe(
      filter(accessRequest => !!accessRequest.centreId),
      switchMap(accessRequest => this.store.select(selectCentreLabelById(accessRequest.centreId!)))
    );
  }
}
