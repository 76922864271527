import { NgModule } from '@angular/core';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AccessDeniedDialogComponent } from './access-denied-dialog/access-denied-dialog.component';

@NgModule({
  declarations: [
    SuccessDialogComponent,
    ErrorDialogComponent,
    AccessDeniedDialogComponent
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    MatButtonModule
  ],
  exports: [
    SuccessDialogComponent,
    ErrorDialogComponent,
  ]
})
export class DialogUnicornModule {
}
