import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { Ars } from '@access-request-unicorn/models/ars.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { map, Observable, startWith, Subject, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { SaveUserArs } from '@access-request-unicorn/state/app.actions';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import { listsFeature } from '@access-request-unicorn/state/lists/lists.reducer';

@Component({
  selector: 'access-request-ars-selection',
  templateUrl: './ars-selection.component.html',
  styleUrls: ['./ars-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArsSelectionComponent implements OnInit, OnDestroy {

  private destroy$!: Subject<boolean>;
  @Input() config!: DisplayTextConfig;
  @Output() openTooltip = new EventEmitter<string>();
  arsList$!: Observable<Ars[]>;
  arsCtrl!: FormControl;
  isAdminCtrl!: FormControl;
  isQACtrl!: FormControl;
  showCheckboxes$!: Observable<boolean>;

  constructor(private formBuilder: FormBuilder,
              private store: Store) {
  }

  ngOnInit() {
    this.destroy$ = new Subject<boolean>();
    this.connectStoreObservables();
    this.initForm();
    this.patchFormIfValuesAlreadySelected();
    this.connectFormListeners();
  }

  onContinue() {
    this.store.dispatch(SaveUserArs({
      ars: this.arsCtrl.value,
      userIsArsAdmin: this.isAdminCtrl.value,
      userIsArsQA: this.isQACtrl.value,
    }));
  }

  onOpenTooltip(tooltip: string) {
    this.openTooltip.next(tooltip);
  }

  private initForm() {
    this.arsCtrl = this.formBuilder.control('');
    this.isAdminCtrl = this.formBuilder.control(false);
    this.isQACtrl = this.formBuilder.control(false);
  }

  private connectFormListeners() {
    this.showCheckboxes$ = this.arsCtrl.valueChanges.pipe(
      startWith(this.arsCtrl.value),
      map(ars => !!ars)
    );
  }

  private patchFormIfValuesAlreadySelected() {
    this.store.select(appFeature.selectUserArsId).pipe(
      take(1),
      tap(ars => this.arsCtrl.patchValue(ars))
    ).subscribe();
    this.store.select(appFeature.selectUserIsAdmin).pipe(
      take(1),
      tap(userIsAdmin => this.isAdminCtrl.patchValue(userIsAdmin))
    ).subscribe();
    this.store.select(appFeature.selectUserIsQA).pipe(
      take(1),
      tap(userIsQA => this.isQACtrl.patchValue(userIsQA))
    ).subscribe();
  }

  private connectStoreObservables() {
    this.arsList$ = this.store.select(listsFeature.selectArsList);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
