import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ServerError } from '@admin-unicorn/lists/models/server-error.model';
import { BnaError } from 'types-unicorn';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response: ServerError) => throwError(() => BnaError.fromServerError(response))),
    );
  }

}
