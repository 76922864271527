import { PersonalInfo } from '@access-request-unicorn/models/personal-info.model';
import { CentreRequest } from '@access-request-unicorn/models/centre-request.model';

export class AccessRequest {

  centreId?: number;
  newCentreRequest?: CentreRequest;
  arsId?: number;
  isAdmin!: boolean;
  isQuality!: boolean;
  isSupervisor!: boolean;

  constructor(public userType: string,
              public personalInfo: PersonalInfo,
              accessRequestInfo: AccessRequestInformation,
              public commentaire: string = '') {

    this.setUserPermissions(accessRequestInfo);
    this.setUserCentreOrArs(accessRequestInfo);
    this.addNewCentreRequestIfApplicable(accessRequestInfo);
  }

  private addNewCentreRequestIfApplicable(accessRequestInfo: AccessRequestInformation) {
    if (accessRequestInfo.centreRequest) {
      this.newCentreRequest = accessRequestInfo.centreRequest;
    }
  }

  private setUserCentreOrArs(accessRequestInfo: AccessRequestInformation) {
    if (accessRequestInfo.arsId) {
      this.arsId = accessRequestInfo.arsId;
    }
    if (accessRequestInfo.centreId) {
      this.centreId = accessRequestInfo.centreId;
    }
  }

  private setUserPermissions(accessRequestInfo: AccessRequestInformation) {
    this.isAdmin = !!accessRequestInfo.isAdmin;
    this.isQuality = !!accessRequestInfo.isQA;
    this.isSupervisor = !!accessRequestInfo.isSupervisor;
  }
}

export type AccessRequestInformation = {
  centreId?: number,
  centreRequest?: CentreRequest,
  arsId?: number,
  isAdmin?: boolean,
  isQA?: boolean,
  isSupervisor?: boolean,
}
