import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogData } from '../models';

@Component({
  selector: 'ellipse-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessDialogData,
              private dialogRef: MatDialogRef<SuccessDialogComponent>) {}

  ngOnInit(): void {
    setTimeout(() => this.dialogRef.close(), this.data.duration ?? 2000);
  }
}
