import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity';
import { Ars } from '@access-request-unicorn/models/ars.model';
import { List } from 'types-unicorn';
import { environment } from '@access-request-unicorn/env/environment';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  private APP_LISTS = [
    'TypeUtilisateur',
    'Departement',
    'ApplicationSaisie',
    'CentreType',
    'CentreLabel'
  ];

  private ACTIVE_LISTS_URL = `${environment.accessRequestBaseUrl}/lists/active/${this.APP_LISTS.join(',')}`;
  private ARS_LIST_URL = `${environment.accessRequestBaseUrl}/ars/list`;

  constructor(private http: HttpClient) {}

  getAppLists(): Observable<Dictionary<List>> {
    return this.http.get<List[]>(this.ACTIVE_LISTS_URL).pipe(
      map(lists => this.toDictionary(lists))
    );
  }

  getArsList(): Observable<Ars[]> {
    return this.http.get<Ars[]>(this.ARS_LIST_URL);
  }

  private toDictionary(lists: List[]) {
    return lists.reduce((acc, curr) => ({
      ...acc,
      [curr.uniqueKey]: curr
    }), {});
  }
}
