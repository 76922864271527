<mat-card>
  <mat-card-header>
    <h1 matCardTitle *ngIf="centreTypeLabel$ | async as centreType">
      {{ centreType }}
    </h1>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="centreForm">
      <div class="row">
        <mat-checkbox
          formControlName="hasParentCentre">{{ config.centreCreation.centreDependency.label! }}</mat-checkbox>
      </div>
      <div *ngIf="doesNotHaveParentCentre$ | async" class="row">
        <div class="field-with-tooltip full-width">
          <mat-form-field *ngIf="arsList$ | async as arsList">
            <mat-label>{{ config.centreCreation.arsSelection.label! }}</mat-label>
            <mat-select formControlName="arsId">
              <mat-option *ngFor="let item of arsList" [value]="item.id">
                {{ item.nom }}
              </mat-option>
            </mat-select>
            <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.arsSelection.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error></mat-error>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="hasParentCentre$ | async">
        <access-request-find-centre-form *ngIf="findCentreFormData$ | async as data"
                                         [config]="config"
                                         [departmentList]="data.departmentList"
                                         [filteredCentres]="data.filteredCentres"
                                         [minKeywordLength]="data.minKeywordLength"
                                         [selectedCentre]="data.selectedCentre"
                                         (selectCentre)="onSelectParentCentre($event)"
                                         (search)="onSearch($event)"
        />
      </ng-container>
      <div class="row top-row"
           *ngIf="isCentreNonLiberal$ | async">
        <div class="top-row-field">
          <mat-form-field>
            <mat-label>{{ config.centreCreation.finessJuridique.label! }}</mat-label>
            <input type="text" matInput formControlName="finessJuridique">
            <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.finessJuridique.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error
              *ngIf="finessJuridiqueCtrlHasError$ | async">{{ config.centreCreation.finessJuridique.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
        <div class="top-row-field">
          <mat-form-field>
            <mat-label>{{ config.centreCreation.finessGeographique.label! }}</mat-label>
            <input type="text" matInput formControlName="finessGeographique">
            <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.finessGeographique.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error
              *ngIf="finessGeographiqueCtrlHasError$ | async">{{ config.centreCreation.finessGeographique.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="isCentreLiberal$ | async">
        <div class="top-row-field">
          <mat-form-field>
            <mat-label>{{ config.centreCreation.numAdeli.label! }}</mat-label>
            <input type="text" matInput formControlName="numAdeli">
            <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.numAdeli.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error
              *ngIf="numAdeliCtrlHasError$ | async">{{ config.centreCreation.numAdeli.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="field-with-tooltip full-width">
        <mat-form-field *ngIf="applicationsList$ | async as applicationList">
          <mat-label>{{ config.centreCreation.application.label! }}</mat-label>
          <mat-select formControlName="applicationId">
            <mat-option *ngFor="let item of applicationList.items" [value]="item.id">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.application.tooltip!"
                                       class="tooltip-icon"/>
          <mat-error
            *ngIf="applicationCtrlHasError$ | async">{{ config.centreCreation.application.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip centre-label" *ngIf="isCentreNonLiberal$ | async">
        <mat-form-field *ngIf="labelisationChoiceList$ | async as labelisationChoiceList">
          <mat-label>{{ config.centreCreation.centreLabel.label! }}</mat-label>
          <mat-select formControlName="centreLabel">
            <mat-option *ngFor="let item of labelisationChoiceList.items"
                        [value]="item.id">{{ item.label }}</mat-option>
          </mat-select>
          <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.centreLabel.tooltip!"
                                       class="tooltip-icon"/>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip centre-label" *ngIf="showLabelisationYearCtrl$ | async">
        <mat-form-field>
          <mat-label>{{ config.centreCreation.centreLabelAnnee.label! }}</mat-label>
          <input type="text" matInput formControlName="centreLabelAnnee">
          <mat-error
            *ngIf="centreLabelAnneeCtrlHasError$ | async">{{ config.centreCreation.centreLabelAnnee.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip full-width">
        <mat-form-field>
          <mat-label>{{ config.centreCreation.centreName.label! }}</mat-label>
          <input type="text" matInput formControlName="nomCentre">
          <mat-error
            *ngIf="centreNameCtrlHasError$ | async">{{ config.centreCreation.centreName.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip full-width">
        <mat-form-field>
          <mat-label>Adresse</mat-label>
          <input type="text" matInput formControlName="adRue1">
          <mat-error
            *ngIf="centreAddressCtrlHasError$ | async">{{ config.centreCreation.address.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip full-width">
        <mat-form-field>
          <mat-label>Adresse (suite)</mat-label>
          <input type="text" matInput formControlName="adRue2">
        </mat-form-field>
      </div>
      <div class="zip-and-town">
        <div class="field-with-tooltip zip">
          <mat-form-field>
            <mat-label>Code postal</mat-label>
            <input type="text" matInput formControlName="adCp">
            <mat-error
              *ngIf="centreZipCodeCtrlHasError$ | async">{{ config.centreCreation.zipCode.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
        <div class="field-with-tooltip town">
          <mat-form-field>
            <mat-label>Ville</mat-label>
            <input type="text" matInput formControlName="adVille">
            <mat-error
              *ngIf="centreTownCodeCtrlHasError$ | async">{{ config.centreCreation.town.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="field-with-tooltip phone">
        <mat-form-field>
          <mat-label>{{ config.centreCreation.telephone.label! }}</mat-label>
          <input type="text" matInput formControlName="telephone">
          <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.telephone.tooltip!"
                                       class="tooltip-icon"/>
          <mat-error
            *ngIf="centrePhoneCtrlHasError$ | async">{{ config.centreCreation.telephone.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-with-tooltip mail">
        <mat-form-field>
          <mat-label>{{ config.centreCreation.mail.label! }}</mat-label>
          <input type="text" matInput formControlName="mail">
          <access-request-tooltip-icon matSuffix [tooltip]="config.centreCreation.mail.tooltip!" class="tooltip-icon"/>
          <mat-error *ngIf="centreMailCtrlHasError$ | async">{{ config.centreCreation.mail.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
      <div class="checkbox-container">
        <div class="checkboxes">
          <mat-checkbox checked disabled>{{ config.centreSupervisorLabel }}</mat-checkbox>
          <mat-checkbox disabled>{{ config.centreAdminLabel }}</mat-checkbox>
          <mat-checkbox formControlName="isQA">{{ config.centreQALabel }}</mat-checkbox>
        </div>
        <access-request-tooltip-icon (click)="onOpenTooltip(config.centreUserRolesTooltip)"
                                     tooltip="Cliquez pour plus d'informations"
                                     class="vertical-centered-tooltip" />
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button
            color="primary"
            [disabled]="!centreForm.valid"
            (click)="onContinue()">{{ config.firstPageContinueButtonText }}</button>
  </mat-card-actions>
</mat-card>
