import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppEffects } from '@access-request-unicorn/state/app.effects';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { EllipseIconModule } from 'ui-components';
import { StartPageComponent } from '@access-request-unicorn/components/start-page/start-page.component';
import { CentreSelectionComponent } from './components/start-page/centre-selection/centre-selection.component';
import { CentreCreationComponent } from './components/start-page/centre-creation/centre-creation.component';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { PersonalInfoPageComponent } from './components/personal-info-page/personal-info-page.component';
import { ArsSelectionComponent } from './components/start-page/ars-selection/ars-selection.component';
import { SummaryPageComponent } from './components/summary-page/summary-page.component';
import {
  FindCentreFormComponent
} from '@access-request-unicorn/components/start-page/find-centre-form/find-centre-form.component';
import { ErrorInterceptor } from '@access-request-unicorn/interceptors/error.interceptor';
import { DialogUnicornModule } from '../../../dialog-unicorn/src/lib/dialog-unicorn.module';
import {
  TooltipIconComponent
} from '@access-request-unicorn/components/standalone/tooltip-icon/tooltip-icon.component';
import {
  SummaryPersonalInfoComponent
} from './components/summary-page/summary-personal-info/summary-personal-info.component';
import { SummaryUserInfoComponent } from './components/summary-page/summary-user-info/summary-user-info.component';
import {
  SummaryNewCentreRequestComponent
} from './components/summary-page/summary-new-centre-request/summary-new-centre-request.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { configFeature } from '@access-request-unicorn/state/config/config.reducer';
import { ConfigEffects } from '@access-request-unicorn/state/config/config.effects';
import { listsFeature } from '@access-request-unicorn/state/lists/lists.reducer';
import { ListsEffects } from '@access-request-unicorn/state/lists/lists.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    CentreSelectionComponent,
    CentreCreationComponent,
    PersonalInfoPageComponent,
    ArsSelectionComponent,
    SummaryPageComponent,
    FindCentreFormComponent,
    SummaryPersonalInfoComponent,
    SummaryUserInfoComponent,
    SummaryNewCentreRequestComponent,
    ConfirmationPageComponent,
    MainLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatCardModule,
    HttpClientModule,
    StoreModule.forRoot({
      [appFeature.name]: appFeature.reducer,
      [configFeature.name]: configFeature.reducer,
      [listsFeature.name]: listsFeature.reducer
    }),
    StoreDevtoolsModule.instrument({
      logOnly: !isDevMode()
    , connectInZone: true}),
    EffectsModule.forRoot([
      AppEffects,
      ConfigEffects,
      ListsEffects
    ]),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    EllipseIconModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonModule,
    DialogUnicornModule,
    MatDialogModule,
    TooltipIconComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
