import { createFeature, createReducer, on } from '@ngrx/store';
import * as AppActions from './app.actions';
import { Centre } from '@access-request-unicorn/models/centre.model';
import { PersonalInfo } from '@access-request-unicorn/models/personal-info.model';
import { CentreDetails } from '@access-request-unicorn/models/centre-request.model';

export const featureKey = 'app';

export interface AppState {
  userType: string,
  userCentre: {
    type: string,
    isCentre: boolean,
    isLiberal: boolean,
  }
  userIsArsUser: boolean,
  centreExists: boolean | null,
  filteredCentres: Centre[],
  userHasFilteredCentres: boolean,
  userCentreId: number,
  userIsAdmin: boolean,
  userIsQA: boolean,
  userIsSupervisor: boolean,
  userArsId: number,
  personalInfo: PersonalInfo,
  parentCentreId: number,
  centreDetails: CentreDetails & { hasParentCentre: boolean } | null,
  userComments: string,
  requestSent: boolean,
}

export const initialState: AppState = {
  userType: '',
  userCentre: {
    type: '',
    isCentre: false,
    isLiberal: false
  },
  userIsArsUser: false,
  centreExists: null,
  filteredCentres: [],
  userHasFilteredCentres: false,
  userCentreId: 0,
  userIsAdmin: false,
  userIsQA: false,
  userIsSupervisor: false,
  userArsId: 0,
  personalInfo: PersonalInfo.nullPersonalInfo(),
  parentCentreId: 0,
  centreDetails: null,
  userComments: '',
  requestSent: false,
};

export const startPageFormResetter: Partial<AppState> = {
  userArsId: initialState.userArsId,
  userIsAdmin: initialState.userIsAdmin,
  userIsQA: initialState.userIsQA,
  userIsSupervisor: initialState.userIsSupervisor,
  userCentreId: initialState.userCentreId,
  filteredCentres: initialState.filteredCentres,
  userHasFilteredCentres: initialState.userHasFilteredCentres
};

export const appFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      AppActions.SetUserType,
      (state, action): AppState => ({
        ...state,
        userType: action.userType.uniqueKey,
        userCentre: {
          type: action.userType.extra.centreType,
          isCentre: action.userType.extra.isCentre,
          isLiberal: action.userType.extra.isLiberal,
        },
        userIsArsUser: action.userType.extra.isArs ?? false,
        ...startPageFormResetter
      })
    ),
    on(
      AppActions.SaveUserType,
      (state, action): AppState => ({
        ...state,
        userIsAdmin: action.isAdmin,
        userIsQA: action.isQA
      })
    ),
    on(
      AppActions.SetCentreExists,
      (state, action): AppState => ({
        ...state,
        centreExists: action.centreExists,
        filteredCentres: [],
        userCentreId: 0,
        userHasFilteredCentres: false
      })
    ),
    on(
      AppActions.FindCentresSuccess,
      (state, action): AppState => ({
        ...state,
        filteredCentres: action.centres,
        userCentreId: 0,
        userHasFilteredCentres: true
      })
    ),
    on(
      AppActions.SetUserCentre,
      (state, action): AppState => ({
        ...state,
        userCentreId: action.centreId,
      })
    ),
    on(
      AppActions.SaveSelectedCentre,
      (state, action): AppState => ({
        ...state,
        userArsId: 0,
        userIsAdmin: action.userIsCentreAdmin,
        userIsQA: action.userIsCentreQA,
      })
    ),
    on(
      AppActions.SaveUserArs,
      (state, action): AppState => ({
        ...state,
        userCentreId: 0,
        userArsId: action.ars,
        userIsAdmin: action.userIsArsAdmin,
        userIsQA: action.userIsArsQA,
      })
    ),
    on(
      AppActions.SaveUserPersonalInfo,
      (state, action): AppState => ({
        ...state,
        personalInfo: action.personalInfo
      })
    ),
    on(
      AppActions.SetParentCentre,
      (state, action): AppState => ({
        ...state,
        parentCentreId: action.centreId
      })
    ),
    on(
      AppActions.SaveCentreRequest,
      (state, action): AppState => ({
        ...state,
        centreDetails: {
          ...action.centreDetails,
          centreType: state.userCentre.type,
        },
        userIsQA: action.centreDetails.isQA,
        userIsSupervisor: true // user creating a new centre is always a supervisor
      })
    ),
    on(
      AppActions.SubmitAccessRequest,
      (state, action): AppState => ({
        ...state,
        userComments: action.commentaire
      })
    ),
    on(
      AppActions.SubmitAccessRequestSuccess,
      (state): AppState => ({
        ...state,
        requestSent: true
      })
    )
  ),

});
