import { Dictionary } from '@ngrx/entity';
import { List } from 'types-unicorn';
import { Ars } from '@access-request-unicorn/models/ars.model';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as ListsActions from '@access-request-unicorn/state/lists/lists.actions';

export const featureKey = 'lists';

export interface ListsState {
  lists: Dictionary<List>,
  arsList: Ars[],
}

export const initialState: ListsState = {
  lists: {},
  arsList: [],
}

export const listsFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      ListsActions.GetAppListsSuccess,
      (state, action): ListsState => ({
        ...state,
        lists: action.lists
      })
    ),
    on(
      ListsActions.GetArsListSuccess,
      (state, action): ListsState => ({
        ...state,
        arsList: action.arsList
      })
    ),
  )
});
