import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as ConfigActions from './config.actions';
import { ConfigService } from '@access-request-unicorn/services/config.service';

@Injectable()
export class ConfigEffects {

  getAppConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.config.getAppConfig().pipe(
        map(appConfig => ConfigActions.GetAppConfigSuccess({ appConfig })),
        catchError(error => of(ConfigActions.GetAppConfigFailure({ error })))
      ))
    );
  });

  constructor(private actions$: Actions,
              private config: ConfigService) {
  }
}
