import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipseIconModule } from 'ui-components';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'access-request-tooltip-icon',
  standalone: true,
  imports: [
    CommonModule,
    EllipseIconModule,
    MatTooltipModule
  ],
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipIconComponent {
  @Input() tooltip: string = '';
  @Input() tooltipPosition: 'above' | 'below' | 'left' | 'right' | 'before' | 'after' = 'right';
}
