import { createFeature, createReducer, on } from '@ngrx/store';
import * as ConfigActions from './config.actions';
import { DefaultTextConfig, DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';

export const featureKey = 'config';

export interface ConfigState {
  displayText: DisplayTextConfig;
  keywordMinLengthBeforeCentreRequest: number;
  earliestLabelYear: number;
}

export const initialState: ConfigState = {
  displayText: DefaultTextConfig,
  keywordMinLengthBeforeCentreRequest: 3,
  earliestLabelYear: 2010
}

export const configFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(
      ConfigActions.GetAppConfigSuccess, (state, action): ConfigState => ({
        ...state,
        ...action.appConfig,
      })
    ),
  )
});
