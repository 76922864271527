export class DisplayTextConfig {
  title!: string;
  description!: string;
  userType!: FormFieldConfig;
  centreExists!: FormFieldConfig;
  centreSearchDepartment!: FormFieldConfig;
  centreSearchKeyword!: FormFieldConfig;
  centreAdminLabel!: string;
  centreQALabel!: string;
  centreSupervisorLabel!: string;
  centreUserRolesTooltip!: string;
  firstPageContinueButtonText!: string;
  centreCreation!: {
    centreDependency: FormFieldConfig;
    arsSelection: FormFieldConfig;
    finessJuridique: FormFieldConfig;
    finessGeographique: FormFieldConfig;
    numAdeli: FormFieldConfig;
    application: FormFieldConfig;
    centreLabel: FormFieldConfig;
    centreLabelAnnee: FormFieldConfig;
    centreName: FormFieldConfig;
    address: FormFieldConfig;
    zipCode: FormFieldConfig;
    town: FormFieldConfig;
    telephone: FormFieldConfig;
    mail: FormFieldConfig;
  };
  arsAdminLabel!: string;
  arsQALabel!: string;
  arsUserRolesTooltip!: string;
  otherAdminLabel!: string;
  otherQALabel!: string;
  otherUserRolesTooltip!: string;
  personalInfo!: {
    nom: FormFieldConfig;
    prenom: FormFieldConfig;
    telFixe: FormFieldConfig;
    telMobile: FormFieldConfig;
    mail: FormFieldConfig;
    charteCheckboxLabel: string;
    consultChartButtonLabel: string;
    downloadChartButtonLabel: string;
    continueButtonText: string;
  };
  summaryPage!: {
    personalInfoCardTitle: string;
    userInfoCardTitle: string;
    newCentreRequestCardTitle: string;
    commentFieldLabel: string;
    submitButtonLabel: string;
  };
  confirmationPage!: {
    title: string;
    description: string;
  };
}

export const DefaultTextConfig: DisplayTextConfig = {
  arsAdminLabel: 'Je suis administrateur de ma structure',
  arsQALabel: 'Je suis responsable qualité de ma structure',
  arsUserRolesTooltip: 'Lorem ipsum etc etc etc',
  centreAdminLabel: 'Je suis administrateur de mon centre',
  centreCreation: {
    application: {
      label: `Application`,
      tooltip: `Application`
    },
    arsSelection: {
      label: `ARS`,
      tooltip: `ARS dont dépend le centre`
    },
    centreDependency: {
      label: `Mon centre dépend d'un autre centre`,
    },
    centreLabel: {
      label: `Centre labélisé`,
      tooltip: `Centre labélisé`
    },
    centreLabelAnnee: {
      label: `Année de labélisation`
    },
    centreName: {
      label: `Nom du centre (ex. CMRR de Nice, CM de Beauvais, CL du Dr Dupont…)`,
    },
    finessGeographique: {
      label: `Numéro Finess Géographique de mon centre`,
      tooltip: `Numéro Finess Géographique de mon centre`
    },
    finessJuridique: {
      label: `Numéro Finess Juridique de mon centre`,
      tooltip: `Numéro Finess Juridique de mon centre`,
      errorMessage: `Le numéro Finess Juridique est composé de 9 chiffres`
    },
    mail: {
      label: `Adresse mail`,
      tooltip: `Adresse mail du standard`
    },
    numAdeli: {
      label: `Numéro ADELI`,
      tooltip: `Numéro ADELI du praticien`
    },
    telephone: {
      label: `Numéro de téléphone`,
      tooltip: `Numéro de téléphone du standard`
    },
    address: {
      errorMessage: 'Le champ adresse comporte une erreur'
    },
    zipCode: {
      errorMessage: 'Le champ code postal comporte une erreur'
    },
    town: {
      errorMessage: 'Le champ ville comporte une erreur'
    }
  },
  centreExists: {
    label: 'Mon centre',
    tooltip: `Le centre est déjà enregistré`
  },
  centreQALabel: 'Je suis responsable qualité de mon centre',
  centreSearchDepartment: {
    label: 'Département',
  },
  centreSearchKeyword: {
    label: `Mot(s) clé(s)`,
    tooltip: `Mots clés pour chercher votre centre`
  },
  centreSupervisorLabel: 'Je suis superviseur de mon centre',
  centreUserRolesTooltip: 'Lorem ipsum etc etc etc',
  confirmationPage: { description: '', title: '' },
  description: 'L\'accès à la banque nationale est reservé aux utilisateurs autorisés et enregistrés. Veuillez remplir le formulaire pour obtenir vos identifiants de connexion et pouvoir ainsi accéder à la BNA.',
  firstPageContinueButtonText: 'CONTINUER - SAISIE DE MES INFORMATIONS PERSONNELLES',
  otherAdminLabel: 'Je suis administrateur de ma structure',
  otherQALabel: 'Je suis responsable qualité de ma structure',
  otherUserRolesTooltip: 'Lorem ipsum etc etc etc',
  personalInfo: {
    charteCheckboxLabel: `Je certifie avoir pris connaissance et accepté la charte d'utilisation de la BNA`,
    consultChartButtonLabel: `Consulter la charte`,
    continueButtonText: `VÉRIFIER ET VALIDER MA DEMANDE`,
    downloadChartButtonLabel: 'Télécharger la charte',
    nom: {},
    prenom: {},
    mail: {
      label: `Adresse mail`,
      tooltip: `Adresse mail`
    },
    telFixe: {
      label: `Téléphone fixe`,
      tooltip: `Téléphone fixe`
    },
    telMobile: {
      label: `Téléphone mobile`,
      tooltip: `Téléphone mobile`
    }
  },
  summaryPage: {
    commentFieldLabel: 'Remarques',
    newCentreRequestCardTitle: 'Demande de création de centre',
    personalInfoCardTitle: 'Informations personnelles',
    submitButtonLabel: 'ENVOYER',
    userInfoCardTitle: 'Informations utilisateur'
  },
  title: 'Demande d\'accès à la Banque-Nationale-Alzheimer',
  userType: {
    label: 'Je suis',
    tooltip: `Type d'utilisateur`
  }

}

type FormFieldConfig = {
  label?: string;
  tooltip?: string;
  errorMessage?: string;
}
