import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from '@access-request-unicorn/components/start-page/start-page.component';
import {
  PersonalInfoPageComponent
} from '@access-request-unicorn/components/personal-info-page/personal-info-page.component';
import { SummaryPageComponent } from '@access-request-unicorn/components/summary-page/summary-page.component';
import {
  ConfirmationPageComponent
} from '@access-request-unicorn/components/confirmation-page/confirmation-page.component';
import { CanActivatePersonalInfo } from '@access-request-unicorn/guards/can-activate-personal-info.guard';
import { CanActivateSummary } from '@access-request-unicorn/guards/can-activate-summary.guard';
import { CanActivateConfirmation } from '@access-request-unicorn/guards/can-activate-confirmation.guard';
import { MainLayoutComponent } from '@access-request-unicorn/components/main-layout/main-layout.component';

const routes: Routes = [
  { path: 'access-request', component: MainLayoutComponent, children: [
      { path: 'start', component: StartPageComponent },
      { path: 'personal-info', canActivate: [CanActivatePersonalInfo], component: PersonalInfoPageComponent },
      { path: 'summary', canActivate: [CanActivateSummary], component: SummaryPageComponent },
      { path: 'confirmation', canActivate: [CanActivateConfirmation], component: ConfirmationPageComponent },
      { path: '', pathMatch: 'full', redirectTo: 'start' },
    ] },
  { path: 'validation/:encoded', loadComponent: () => import('./components/standalone/validate-email/validate-email.component').then(c => c.ValidateEmailComponent) },
  { path: '', pathMatch: 'full', redirectTo: 'access-request' },
  { path: '**', redirectTo: 'access-request' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
