import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';

@Component({
  selector: 'access-request-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  textConfig$!: Observable<DisplayTextConfig>;

  constructor(private config: ConfigFacade) {}

  ngOnInit() {
    this.textConfig$ = this.config.displayText$;
  }
}
