import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import {
  selectShowBasicContinueButton,
  selectShowCentreCreation,
  selectShowCentreSelection,
  selectUserAdminAndQADetails,
  selectUserCentreIsCentre,
  selectUserIsNotCentreOrArsAndCanBeAdminOrQA,
  selectUserTypeListItem
} from '@access-request-unicorn/state/app.selectors';
import { List } from 'types-unicorn';
import { SaveUserType, SetCentreExists, SetUserType } from '@access-request-unicorn/state/app.actions';
import { UserTypeListItem } from '@access-request-unicorn/models/user-type-list-item.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';
import { ListsFacade } from '@access-request-unicorn/services/lists.facade';
import { TooltipModalService } from '@access-request-unicorn/services/tooltip-modal.service';

@Component({
  selector: 'access-request-main-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartPageComponent implements OnInit, OnDestroy {
  private destroy$!: Subject<boolean>;

  textConfig$!: Observable<DisplayTextConfig>;
  userTypeList$!: Observable<List>;
  userTypeCtrl!: FormControl;
  centreExistsCtrl!: FormControl;
  isAdminCtrl!: FormControl;
  isQACtrl!: FormControl;

  showCentreExistsCtrl$!: Observable<boolean>;
  showCentreSelection$!: Observable<boolean>;
  showCentreCreation$!: Observable<boolean>;
  showArsSelection$!: Observable<boolean>;
  showBasicContinueButton$!: Observable<boolean>;
  showCheckboxes$!: Observable<boolean>;

  constructor(private store: Store,
              private formBuilder: FormBuilder,
              private config: ConfigFacade,
              private lists: ListsFacade,
              private tooltipModal: TooltipModalService) {}

  ngOnInit() {
    this.destroy$ = new Subject<boolean>();
    this.initFormControls();
    this.connectStoreObservables();
    this.patchControlsIfValuesSet();
    this.connectFormListeners();
  }

  onContinue() {
    this.store.dispatch(SaveUserType({
      isAdmin: this.isAdminCtrl.value,
      isQA: this.isQACtrl.value
    }));
  }

  onOpenTooltipModal(tooltip: string) {
    this.tooltipModal.openDialogWithTooltip(tooltip);
  }

  private initFormControls() {
    this.userTypeCtrl = this.formBuilder.control('');
    this.centreExistsCtrl = this.formBuilder.control(null, { validators: [Validators.required] });
    this.isAdminCtrl = this.formBuilder.control(false);
    this.isQACtrl = this.formBuilder.control(false);
  }

  private connectStoreObservables() {
    this.textConfig$ = this.config.displayText$;
    this.userTypeList$ = this.lists.getListByUniqueKey('TypeUtilisateur');
    this.showCentreExistsCtrl$ = this.store.select(selectUserCentreIsCentre).pipe(
      tap(showCentreExists => this.resetCentreCtrlIfUserNotCentreType(showCentreExists))
    );
    this.showCentreCreation$ = this.store.select(selectShowCentreCreation);
    this.showCentreSelection$ = this.store.select(selectShowCentreSelection);
    this.showArsSelection$ = this.store.select(appFeature.selectUserIsArsUser);
    this.showBasicContinueButton$ = this.store.select(selectShowBasicContinueButton);
    this.showCheckboxes$ = this.store.select(selectUserIsNotCentreOrArsAndCanBeAdminOrQA);
  }

  private patchControlsIfValuesSet() {
    this.patchUserTypeIfAlreadySelected();
    this.patchCentreExistsIfAlreadySelected();
    this.patchUserExtraIfAlreadySelected();
  }

  private patchCentreExistsIfAlreadySelected() {
    this.showCentreExistsCtrl$.pipe(
      take(1),
      filter(showCtrl => !!showCtrl),
      switchMap(() => this.store.select(appFeature.selectCentreExists)),
      take(1),
      tap(centreExists => this.centreExistsCtrl.patchValue(centreExists, { emitEvent: false })),
    ).subscribe();
  }

  private patchUserTypeIfAlreadySelected() {
    this.store.select(selectUserTypeListItem).pipe(
      take(1),
      tap(userType => {
        if (userType) {
          this.userTypeCtrl.patchValue(userType, { emitEvent: false });
        }
      }),
    ).subscribe();
  }

  private patchUserExtraIfAlreadySelected() {
    this.store.select(selectUserAdminAndQADetails).pipe(
      take(1),
      tap(({ isAdmin, isQA }) => {
        this.isAdminCtrl.patchValue(isAdmin);
        this.isQACtrl.patchValue(isQA);
      })
    ).subscribe();
  }

  private resetCentreCtrlIfUserNotCentreType(showCentreExists: boolean) {
    if (!showCentreExists) {
      this.centreExistsCtrl.patchValue(null);
    }
  }

  private connectFormListeners() {
    this.dispatchUserTypeOnChange();
    this.dispatchCentreExistsOnChange();
  }

  private dispatchUserTypeOnChange() {
    this.userTypeCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((item: UserTypeListItem) => {
        this.store.dispatch(SetUserType({ userType: item }));
        this.centreExistsCtrl.patchValue(null);
      })
    ).subscribe();
  }

  private dispatchCentreExistsOnChange() {
    this.centreExistsCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(centreExists => this.store.dispatch(SetCentreExists({ centreExists })))
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
