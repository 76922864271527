import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CentreRequest } from '@access-request-unicorn/models/centre-request.model';
import { filter, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectAccessRequest,
  selectApplicationLabelById,
  selectArsLabelById,
  selectCentreLabelById,
  selectCentreLabelLabelById
} from '@access-request-unicorn/state/app.selectors';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';

@Component({
  selector: 'access-request-summary-new-centre-request',
  templateUrl: './summary-new-centre-request.component.html',
  styleUrls: ['./summary-new-centre-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryNewCentreRequestComponent implements OnInit {
  newCentreRequest$!: Observable<CentreRequest>;
  parentCentreLabel$!: Observable<string>;
  applicationLabel$!: Observable<string>;
  centreArsLabel$!: Observable<string>;
  centreLabelLabel$!: Observable<string>;
  config$!: Observable<DisplayTextConfig>;

  constructor(private store: Store,
              private config: ConfigFacade) {}

  ngOnInit() {
    this.config$ = this.config.displayText$;
    const accessRequest$ = this.store.select(selectAccessRequest).pipe(
      shareReplay(1)
    );
    this.newCentreRequest$ = accessRequest$.pipe(
      filter(accessRequest => !!accessRequest.newCentreRequest),
      map(accessRequest => accessRequest.newCentreRequest!),
      shareReplay(1)
    );
    this.parentCentreLabel$ = this.newCentreRequest$.pipe(
      filter(centreRequest => !!centreRequest.centreParentId),
      switchMap(centreRequest => this.store.select(selectCentreLabelById(centreRequest.centreParentId!)))
    );
    this.applicationLabel$ = this.newCentreRequest$.pipe(
      switchMap(centreRequest => this.store.select(selectApplicationLabelById(centreRequest.applicationId)))
    );
    this.centreArsLabel$ = this.newCentreRequest$.pipe(
      filter(centreRequest => !!centreRequest.arsId),
      switchMap(centreRequest => this.store.select(selectArsLabelById(centreRequest.arsId!)))
    );
    this.centreLabelLabel$ = this.newCentreRequest$.pipe(
      filter(centreRequest => !!centreRequest.centreLabel),
      switchMap(centreRequest => this.store.select(selectCentreLabelLabelById(centreRequest.centreLabel)))
    );
  }
}
