import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, startWith, Subject, take, takeUntil, tap } from 'rxjs';
import {
  selectFindCentreFormData,
  selectUserAdminAndQADetails,
  selectUserHasSelectedCentre
} from '@access-request-unicorn/state/app.selectors';
import { FormBuilder, FormControl } from '@angular/forms';
import { FindCentres, SaveSelectedCentre, SetUserCentre } from '@access-request-unicorn/state/app.actions';
import { FindCentreQuery } from '@access-request-unicorn/models/find-centre-query.model';
import { FindCentreFormData } from '@access-request-unicorn/models/find-centre-form-data.model';

@Component({
  selector: 'access-request-centre-selection',
  templateUrl: './centre-selection.component.html',
  styleUrls: ['./centre-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentreSelectionComponent implements OnInit, OnDestroy {
  private destroy$!: Subject<boolean>;
  @Input() config!: DisplayTextConfig;
  @Output() openTooltip = new EventEmitter<string>();
  userHasSelectedCentre$!: Observable<boolean>;
  findCentreFormData$!: Observable<FindCentreFormData>;

  isAdminCtrl!: FormControl;
  isQACtrl!: FormControl;
  isSupervisorCtrl!: FormControl;

  constructor(private store: Store,
              private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.destroy$ = new Subject<boolean>();
    this.initForm();
    this.connectFormListeners();
    this.connectStoreObservables();
    this.patchUserAdminOrQAIfAlreadySelected();
  }

  onContinue() {
    this.store.dispatch(SaveSelectedCentre({
      userIsCentreAdmin: this.isAdminCtrl.value,
      userIsCentreQA: this.isQACtrl.value,
      userIsCentreSupervisor: this.isSupervisorCtrl.value,
    }));
  }

  onSelectCentre(centreId: number) {
    this.store.dispatch(SetUserCentre({ centreId }));
  }

  onSearch(query: FindCentreQuery) {
    this.store.dispatch(FindCentres({ query }));
    this.resetCentreSelectionControls();
  }

  onOpenTooltip(tooltip: string) {
    this.openTooltip.next(tooltip);
  }

  private connectStoreObservables() {
    this.userHasSelectedCentre$ = this.store.select(selectUserHasSelectedCentre);
    this.findCentreFormData$ = this.store.select(selectFindCentreFormData());
  }

  private patchUserAdminOrQAIfAlreadySelected() {
    this.store.select(selectUserAdminAndQADetails).pipe(
      take(1),
      tap(({ isAdmin, isQA, isSupervisor }) => {
        this.isAdminCtrl.patchValue(isAdmin, { emitEvent: false });
        this.isQACtrl.patchValue(isQA, { emitEvent: false });
        this.isSupervisorCtrl.patchValue(isSupervisor, { emitEvent: false })
      })
    ).subscribe();
  }

  private resetCentreSelectionControls() {
    this.isAdminCtrl.patchValue(false);
    this.isQACtrl.patchValue(false);
    this.isSupervisorCtrl.patchValue(false);
  }

  private initForm() {
    this.isAdminCtrl = this.formBuilder.control(false);
    this.isQACtrl = this.formBuilder.control(false);
    this.isSupervisorCtrl = this.formBuilder.control(false);
  }

  private connectFormListeners() {
    this.isAdminCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.isAdminCtrl.value),
      distinctUntilChanged(),
      tap(isAdmin => {
        if (isAdmin) {
          this.isSupervisorCtrl.disable();
        } else {
          this.isSupervisorCtrl.enable();
        }
      })
    ).subscribe();
    this.isSupervisorCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.isSupervisorCtrl.value),
      distinctUntilChanged(),
      tap(isSupervisor => {
        if (isSupervisor) {
          this.isAdminCtrl.disable();
        } else {
          this.isAdminCtrl.enable();
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
