import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { List } from 'types-unicorn';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { FindCentreQuery } from '@access-request-unicorn/models/find-centre-query.model';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { Centre } from '@access-request-unicorn/models/centre.model';

@Component({
  selector: 'access-request-find-centre-form',
  templateUrl: './find-centre-form.component.html',
  styleUrls: ['./find-centre-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindCentreFormComponent implements OnInit, OnDestroy {

  private destroy$!: Subject<boolean>;
  private _filteredCentres!: Centre[] | null;
  private selectedCentreQueue$ = new ReplaySubject<number>(1);

  @Input() departmentList!: List;
  @Input() minKeywordLength!: number;
  @Input() config!: DisplayTextConfig;
  @Input()
  get filteredCentres(): Centre[] | null { return this._filteredCentres; }
  set filteredCentres(centres: Centre[] | null) {
    this._filteredCentres = centres;
    this.selectOnlyCentreIfOnlyOneReturned(centres);
  }
  @Input() set selectedCentre(centreId: number) {
    this.addCentreToQueue(centreId);
  }


  @Output() search = new EventEmitter<FindCentreQuery>();
  @Output() selectCentre = new EventEmitter<number>();
  departmentCtrl!: FormControl<string>;

  keywordCtrl!: FormControl<string>;
  selectedCentreCtrl!: FormControl<number[]>;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.destroy$ = new Subject<boolean>();
    this.initForms();
    this.connectSearchListeners();
    this.connectSelectedCentreListener();
  }

  selectOnlyCentreIfOnlyOneReturned(centres: Centre[] | null): void {
    if (centres?.length === 1 && this.selectedCentreCtrl) {
      this.selectedCentreCtrl.patchValue([centres[0].id]);
    }
  }

  initForms() {
    this.departmentCtrl = this.formBuilder.control<string>('', { nonNullable: true });
    this.keywordCtrl = this.formBuilder.control<string>('', {
      validators: [Validators.maxLength(100)],
      nonNullable: true
    });
    this.selectedCentreCtrl = this.formBuilder.control<number[]>([], { nonNullable: true });
    this.getSelectedCentreFromQueueAndPatchControl();
  }

  private getSelectedCentreFromQueueAndPatchControl() {
    this.selectedCentreQueue$.pipe(
      take(1),
      tap(centreId => this.selectedCentreCtrl.patchValue([centreId], { emitEvent: false }))
    ).subscribe();
  }

  private connectSearchListeners() {
    const selectedDepartment$ = this.getDepartmentListener();
    const keyword$ = this.getKeywordListener();
    const search$ = this.buildSearchListener(selectedDepartment$, keyword$);
    search$.subscribe();
  }

  private addCentreToQueue(centreId: number) {
    this.selectedCentreQueue$.next(centreId);
  }

  private buildSearchListener(selectedDepartment$: Observable<string>, keyword$: Observable<string>) {
    return combineLatest([selectedDepartment$, keyword$]).pipe(
      takeUntil(this.destroy$),
      filter(([department, keyword]) => !!department || !!keyword),
      tap(([department, keyword]) => {
        console.log(department, keyword);
        this.resetCentreSelectionControl();
        this.search.emit({ department, keyword });
      })
    );
  }

  private getKeywordListener() {
    return this.keywordCtrl.valueChanges.pipe(
      startWith(this.keywordCtrl.value),
      filter(keyword => this.keywordIsEmptyStringOrAtLeastMinLength(keyword, this.minKeywordLength)),
      debounceTime(500),
      distinctUntilChanged(),
    );
  }

  private getDepartmentListener() {
    return this.departmentCtrl.valueChanges.pipe(
      startWith(this.departmentCtrl.value),
    );
  }

  private keywordIsEmptyStringOrAtLeastMinLength(keyword: string, minLength: number) {
    return keyword.length >= minLength || keyword.length === 0;
  }

  private resetCentreSelectionControl() {
    this.selectedCentreCtrl.patchValue([]);
  }

  private connectSelectedCentreListener() {
    this.selectedCentreCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(centreIdInArray => this.selectCentre.emit(centreIdInArray[0])) // Material selection list always returns an array, even if only one value and multiple set to false
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
