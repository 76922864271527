<ng-container *ngIf="config$ | async as config">
  <mat-card *ngIf="accessRequest$ | async as accessRequest">
    <mat-card-header>
      <h1 matCardTitle>{{ config.summaryPage.userInfoCardTitle }}</h1>
    </mat-card-header>
    <mat-card-content>
      <div class="summary-container">
        <div class="summary-item half-width" *ngIf="userTypeLabel$ | async as userTypeLabel">
          <div class="summary-label">Type d'utilisateur :</div>
          <div class="summary-value">{{ userTypeLabel }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="arsLabel$ | async as arsLabel">
          <div class="summary-label">Mon ARS :</div>
          <div class="summary-value">{{ arsLabel }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="centreLabel$ | async as centreLabel">
          <div class="summary-label">Mon centre :</div>
          <div class="summary-value">{{ centreLabel }}</div>
        </div>
        <div class="summary-item full-width" *ngIf="accessRequest.isAdmin">
          <div class="summary-value">
            <ellipse-icon iconName="fa-regular fa-check"/>
            {{ config.otherAdminLabel }}
          </div>
        </div>
        <div class="summary-item full-width" *ngIf="accessRequest.isQuality">
          <div class="summary-value">
            <ellipse-icon iconName="fa-regular fa-check"/>
            {{ config.otherQALabel }}
          </div>
        </div>
        <div class="summary-item full-width" *ngIf="accessRequest.isSupervisor">
          <div class="summary-value">
            <ellipse-icon iconName="fa-regular fa-check"/>
            {{ config.centreSupervisorLabel }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
