import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@access-request-unicorn/env/environment';
import { Centre } from '@access-request-unicorn/models/centre.model';
import { CreateAccessRequestDto } from 'types-unicorn';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private ACCESS_REQUEST_SUBMISSION_URL = `${environment.accessRequestBaseUrl}/access-request`;
  private FIND_CENTRES_URL = `${environment.accessRequestBaseUrl}/centres/list`;

  constructor(private http: HttpClient) {}

  submitAccessRequest(accessRequestDto: CreateAccessRequestDto): Observable<any> {
    return this.http.post(this.ACCESS_REQUEST_SUBMISSION_URL, { ...accessRequestDto });
  }

  findCentres(department: string = '', keyword: string = '', centreTypeId?: number): Observable<Centre[]> {
    const params = new HttpParams()
      .set('departement', department)
      .set('keyword', keyword)
      .set('centreType', centreTypeId?.toString() ?? '');
    return this.http.get<Centre[]>(this.FIND_CENTRES_URL, { params }).pipe(
      map(centres => centres.map(centre => ({ ...centre, id: +centre.id })))
    );
  }

}
