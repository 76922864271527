import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { appFeature } from '@access-request-unicorn/state/app.reducer';

export const CanActivatePersonalInfo: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(appFeature.selectUserType).pipe(
    map(userType => !!userType || router.createUrlTree(['start']))
  );
}
