import { CreateAccessRequestDto } from 'types-unicorn';
import { AccessRequest } from '@access-request-unicorn/models/access-request.model';
import { CentreRequest } from '@access-request-unicorn/models/centre-request.model';

export class CreateAccessRequestDtoFactory {

  private createAccessRequestDto!: CreateAccessRequestDto;

  constructor(private accessRequest: AccessRequest,
              private userTypeId: number) {
  }

  create(): CreateAccessRequestDto {
    this.createAccessRequestDto = {
      utilisateur: this.buildUser(this.accessRequest, this.userTypeId)
    };
    this.setComment();
    this.setNewCentreRequest();

    return this.createAccessRequestDto;
  }

  private setNewCentreRequest() {
    if (this.accessRequest.newCentreRequest) {
      this.createAccessRequestDto.newCentre = this.buildNewCentreRequest(this.accessRequest.newCentreRequest);
    }
  }

  private setComment() {
    if (this.accessRequest.commentaire) {
      this.createAccessRequestDto.commentaire = this.accessRequest.commentaire;
    }
  }

  private buildUser(accessRequest: AccessRequest, userTypeId: number) {
    const utilisateur: CreateAccessRequestDto['utilisateur'] = {
      ...accessRequest.personalInfo,
      type: userTypeId,
      isAdmin: accessRequest.isAdmin,
      isQuality: accessRequest.isQuality,
      isSupervisor: accessRequest.isSupervisor,
    };
    if (accessRequest.arsId) {
      utilisateur.ars = accessRequest.arsId;
    }
    if (accessRequest.centreId) {
      utilisateur.centre = accessRequest.centreId;
    }
    return utilisateur;
  }

  private buildNewCentreRequest(centreRequest: CentreRequest) {
    const newCentreRequest: CreateAccessRequestDto['newCentre'] = this.setRequiredNewCentreFields(centreRequest);
    if (centreRequest.arsId) {
      newCentreRequest.ars = centreRequest.arsId;
    }
    if (centreRequest.centreParentId) {
      newCentreRequest.parent = centreRequest.centreParentId;
    }
    if (centreRequest.centreLabelAnnee) {
      newCentreRequest.labelAnnee = centreRequest.centreLabelAnnee;
    }
    if (centreRequest.finessJuridique) {
      newCentreRequest.numFinessJuridique = centreRequest.finessJuridique;
      newCentreRequest.numFinessGeo = centreRequest.finessGeographique;
    }
    if (centreRequest.numAdeli) {
      newCentreRequest.numAdeli = centreRequest.numAdeli;
    }
    if (centreRequest.adRue2) {
      newCentreRequest.adRue2 = centreRequest.adRue2;
    }
    return newCentreRequest;
  }

  private setRequiredNewCentreFields(centreRequest: CentreRequest) {
    return {
      application: centreRequest.applicationId,
      label: centreRequest.centreLabel,
      nom: centreRequest.nomCentre,
      adRue1: centreRequest.adRue1,
      adCp: centreRequest.adCp,
      adVille: centreRequest.adVille,
      tel: centreRequest.telephone,
      mail: centreRequest.mail
    };
  }
}
