<mat-card>
  <mat-card-header>
    <h1 matCardTitle>Recherche de mon centre</h1>
  </mat-card-header>
  <mat-card-content *ngIf="findCentreFormData$ | async as data">
    <access-request-find-centre-form [config]="config"
                                     [departmentList]="data.departmentList"
                                     [filteredCentres]="data.filteredCentres"
                                     [minKeywordLength]="data.minKeywordLength"
                                     [selectedCentre]="data.selectedCentre"
                                     (search)="onSearch($event)"
                                     (selectCentre)="onSelectCentre($event)"
    />
    <div class="checkboxes-container" *ngIf="userHasSelectedCentre$ | async">
      <div class="checkboxes">
        <mat-checkbox [formControl]="isAdminCtrl">{{ config.centreAdminLabel }}</mat-checkbox>
        <mat-checkbox [formControl]="isQACtrl">{{ config.centreQALabel }}</mat-checkbox>
        <mat-checkbox [formControl]="isSupervisorCtrl">{{ config.centreSupervisorLabel }}</mat-checkbox>
      </div>
      <access-request-tooltip-icon (click)="onOpenTooltip(config.centreUserRolesTooltip)"
                                   tooltip="Cliquez pour plus d'informations"
                                   class="vertical-centered-tooltip" />
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userHasSelectedCentre$ | async">
    <button mat-flat-button
            color="primary"
            (click)="onContinue()">{{ config.firstPageContinueButtonText }}</button>
  </mat-card-actions>
</mat-card>
