import { createSelector } from '@ngrx/store';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import { CentreRequest } from '@access-request-unicorn/models/centre-request.model';
import { AccessRequest, AccessRequestInformation } from '@access-request-unicorn/models/access-request.model';
import { configFeature } from '@access-request-unicorn/state/config/config.reducer';
import { listsFeature } from '@access-request-unicorn/state/lists/lists.reducer';
import { selectList } from '@access-request-unicorn/state/lists/lists.selectors';
import { CreateAccessRequestDtoFactory } from '@access-request-unicorn/factories/create-access-request-dto.factory';

export const selectUserCentreIsCentre = createSelector(
  appFeature.selectUserCentre,
  centre => centre.isCentre
);

export const selectUserCentreIsLiberal = createSelector(
  appFeature.selectUserCentre,
  centre => centre.isLiberal
);

export const selectUserCentreType = createSelector(
  appFeature.selectUserCentre,
  centre => centre.type
);

export const selectIsCentreNonLiberalUser = createSelector(
  selectUserCentreIsCentre,
  selectUserCentreIsLiberal,
  (isCentre, isLiberal) => isCentre && !isLiberal
);

export const selectShowCentreSelection = createSelector(
  selectUserCentreIsCentre,
  appFeature.selectCentreExists,
  (isCentreUser, centreExists) => isCentreUser && centreExists === true
);

export const selectShowCentreCreation = createSelector(
  selectUserCentreIsCentre,
  appFeature.selectCentreExists,
  (isCentreUser, centreExists) => isCentreUser && centreExists === false
);

export const selectUserHasSelectedCentre = createSelector(
  appFeature.selectUserCentreId,
  centreId => centreId > 0
);

export const selectFilteredCentresAfterInitialSearch = createSelector(
  appFeature.selectFilteredCentres,
  appFeature.selectUserHasFilteredCentres,
  (centres, userHasFiltered) => userHasFiltered ? centres : null
);

export const selectUserAdminAndQADetails = createSelector(
  appFeature.selectUserIsAdmin,
  appFeature.selectUserIsQA,
  appFeature.selectUserIsSupervisor,
  (isAdmin: boolean, isQA: boolean, isSupervisor: boolean) => ({ isAdmin, isQA, isSupervisor })
);

export const selectUserCentreTypeLabel = createSelector(
  selectUserCentreType,
  selectList('CentreType'),
  (userCentreType, centreTypes) => centreTypes.items.find(item => item.uniqueKey === userCentreType)?.label ?? ''
);

export const selectFindCentreFormData = (options: {
  selectedParentCentre: boolean
} = { selectedParentCentre: false }) => createSelector(
  selectList('Departement'),
  selectFilteredCentresAfterInitialSearch,
  configFeature.selectKeywordMinLengthBeforeCentreRequest,
  appFeature.selectUserCentreId,
  appFeature.selectParentCentreId,
  (departmentList, filteredCentres, minKeywordLength, selectedCentre, selectedParentCentre) => ({
    departmentList,
    filteredCentres,
    minKeywordLength,
    selectedCentre: options.selectedParentCentre ? selectedParentCentre : selectedCentre
  })
);

export const selectNewCentreRequest = createSelector(
  appFeature.selectCentreDetails,
  selectUserCentreIsLiberal,
  (details, isLiberal) => details ? new CentreRequest(details, isLiberal) : null
);

export const selectAccessRequest = createSelector(
  appFeature.selectUserType,
  appFeature.selectPersonalInfo,
  appFeature.selectUserCentreId,
  selectNewCentreRequest,
  appFeature.selectUserArsId,
  appFeature.selectUserIsAdmin,
  appFeature.selectUserIsQA,
  appFeature.selectUserIsSupervisor,
  appFeature.selectUserComments,
  (userType, personalInfo, centreId, newCentreRequest, userArs, isAdmin, isQA, isSupervisor, comments) => {
    const accessRequestInfo = compileAccessRequestInformation(centreId, newCentreRequest, userArs, isAdmin, isQA, isSupervisor);
    return new AccessRequest(userType, personalInfo, accessRequestInfo, comments);
  }
);

const compileAccessRequestInformation = (centreId: number,
                                         newCentreRequest: CentreRequest | null,
                                         userArs: number,
                                         isAdmin: boolean,
                                         isQA: boolean,
                                         isSupervisor: boolean): AccessRequestInformation => {
  const accessRequestInformation: AccessRequestInformation = {};
  if (centreId > 0) {
    accessRequestInformation.centreId = centreId;
  }
  if (newCentreRequest) {
    accessRequestInformation.centreRequest = newCentreRequest;
  }
  if (userArs > 0) {
    accessRequestInformation.arsId = userArs;
  }
  accessRequestInformation.isAdmin = isAdmin;
  accessRequestInformation.isQA = isQA;
  accessRequestInformation.isSupervisor = isSupervisor;
  return accessRequestInformation;
}

export const selectShowBasicContinueButton = createSelector(
  appFeature.selectUserType,
  selectUserCentreIsCentre,
  selectUserCentreIsLiberal,
  appFeature.selectUserIsArsUser,
  (userType, isCentre, isLiberal, isArsUser) => !!userType && !(isCentre || isLiberal || isArsUser)
);

export const selectUserTypeLabelForUniqueKey = (uniqueKey: string) => createSelector(
  selectList('TypeUtilisateur'),
  (userTypes) => userTypes.items.find(item => item.uniqueKey === uniqueKey)?.label ?? ''
);

export const selectArsLabelById = (id: number) => createSelector(
  listsFeature.selectArsList,
  arsList => arsList.find(ars => ars.id === id)!.nom
);

export const selectCentreLabelById = (id: number) => createSelector(
  appFeature.selectFilteredCentres,
  centres => centres.find(centre => centre.id === id)!.nom
);

export const selectApplicationLabelById = (id: number) => createSelector(
  selectList('ApplicationSaisie'),
  applicationList => applicationList.items.find(app => app.id === id)!.label
);

export const selectCentreLabelLabelById = (id: number) => createSelector(
  selectList('CentreLabel'),
  labelList => labelList.items.find(label => label.id === id)!.label
);

export const selectSelectedUserTypeId = createSelector(
  appFeature.selectUserType,
  selectList('TypeUtilisateur'),
  (userType, userTypes) => userTypes.items.find(user => user.uniqueKey === userType)!.id!
);

export const selectAccessRequestDto = createSelector(
  selectAccessRequest,
  selectSelectedUserTypeId,
  (accessRequest, userType) => new CreateAccessRequestDtoFactory(accessRequest, userType).create()
);

export const selectUserCentreTypeId = createSelector(
  selectUserCentreType,
  selectList('CentreType'),
  (userCentreType, centreTypes) => centreTypes.items.find(centre => centre.uniqueKey === userCentreType)!.id!
);

export const selectUserTypeListItem = createSelector(
  appFeature.selectUserType,
  selectList('TypeUtilisateur'),
  (userType, userTypes) => userTypes.items.find(user => user.uniqueKey === userType)!
);

export const selectUserIsNotCentreOrArsAndCanBeAdminOrQA = createSelector(
  selectUserTypeListItem,
  userItem => !!userItem && !userItem.extra?.['isCentre'] && !userItem.extra?.['isLiberal'] && !userItem.extra?.['isArs'] && !userItem.extra?.['isIndividual']
)
