import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'access-request-tooltip-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './tooltip-modal.component.html',
  styleUrls: ['./tooltip-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { tooltip: string }) {}
}
