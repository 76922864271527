<div class="error-dialog">
  <h1 mat-dialog-title *ngIf="data.error.title">{{ data.error.title }}</h1>
  <mat-dialog-content>
    <p>{{ data.error.message }}</p>
    <ul *ngIf="data.error.subErrors?.length">
      <li *ngFor="let error of data.error.subErrors">{{ error }}</li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>OK</button>
  </mat-dialog-actions>
</div>
