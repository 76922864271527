import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as ListsActions from '@access-request-unicorn/state/lists/lists.actions';
import { ListsService } from '@access-request-unicorn/services/lists.service';

@Injectable()
export class ListsEffects {

  getAppLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.lists.getAppLists().pipe(
        map(lists => ListsActions.GetAppListsSuccess({ lists })),
        catchError(error => of(ListsActions.GetAppListsFailure({ error })))
      ))
    );
  });

  getArsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.lists.getArsList().pipe(
        map(arsList => ListsActions.GetArsListSuccess({ arsList })),
        catchError(error => of(ListsActions.GetArsListFailure({ error })))
      ))
    );
  });

  constructor(private actions$: Actions,
              private lists: ListsService) {}
}
