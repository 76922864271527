export class CreateAccessRequestDto {
  commentaire?: string;

  utilisateur!: {
    nom: string;
    prenom: string;
    telFixe: string;
    telMobile: string;
    mail: string;
    type: number,
    ars?: number,
    centre?: number,
    isAdmin: boolean,
    isQuality: boolean,
    isSupervisor: boolean,
  };

  newCentre?: {
    ars?: number,
    parent?: number,
    application: number,
    label: number,
    labelAnnee?: number,
    numFinessJuridique?: string,
    numFinessGeo?: string,
    numAdeli?: string,
    nom: string,
    adRue1: string,
    adRue2?: string,
    adCp: string;
    adVille: string;
    tel: string;
    mail: string;
  }
}
