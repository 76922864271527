import { HttpErrorResponse } from '@angular/common/http';

export class ServerError extends HttpErrorResponse {
  override error!: {
    errorMessage: string;
    errorContext: string;
    errors: {
      errorCode: number,
      errorItem: string,
      errorMessage: string,
    }[];
    values: {};
  }
}
