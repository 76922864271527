import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'access-request-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationPageComponent {

}
