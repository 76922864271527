import { createAction, props } from '@ngrx/store';
import { BnaError } from 'types-unicorn';
import { Centre } from '@access-request-unicorn/models/centre.model';
import { PersonalInfo } from '@access-request-unicorn/models/personal-info.model';
import { UserTypeListItem } from '@access-request-unicorn/models/user-type-list-item.model';
import { FindCentreQuery } from '@access-request-unicorn/models/find-centre-query.model';
import { CentreDetails } from '@access-request-unicorn/models/centre-request.model';

export const SetUserType = createAction(
  '[StartPageComponent] Set User Type',
  props<{ userType: UserTypeListItem }>()
);

export const SetCentreExists = createAction(
  '[StartPageComponent] Set Centre Exists',
  props<{ centreExists: boolean }>()
);

export const FindParentCentres = createAction(
  '[CentreCreationComponent] Find Parent Centres',
  props<{ query: FindCentreQuery }>()
);

export const FindCentres = createAction(
  '[CentreSelectionComponent] Find Centres',
  props<{ query: FindCentreQuery }>()
);

export const FindCentresSuccess = createAction(
  '[@Effect findCentres$] Find Centres Success',
  props<{ centres: Centre[] }>()
);

export const FindCentresFailure = createAction(
  '[@Effect findCentres$] Find Centres Failure',
  props<{ error: BnaError }>()
);

export const SetUserCentre = createAction(
  '[CentreSelectionComponent] Set User Centre',
  props<{ centreId: number }>()
);

export const ClearUserCentre = createAction(
  '[CentreCreationComponent] Clear User Centre'
);

export const SaveSelectedCentre = createAction(
  '[CentreSelectionComponent] Save Selected Centre',
  props<{ userIsCentreAdmin: boolean, userIsCentreQA: boolean, userIsCentreSupervisor: boolean }>()
);

export const SaveUserArs = createAction(
  '[ArsSelectionComponent] Set User Ars',
  props<{ ars: number, userIsArsAdmin: boolean, userIsArsQA: boolean }>()
);

export const SaveUserPersonalInfo = createAction(
  '[PersonalInfoComponent] Save User Personal Info',
  props<{ personalInfo: PersonalInfo }>()
);

export const SetParentCentre = createAction(
  '[CentreCreationComponent] Set Parent Centre',
  props<{ centreId: number }>()
);

export const SaveCentreRequest = createAction(
  '[CentreCreationComponent] Save Centre Request',
  props<{ centreDetails: CentreDetails & { hasParentCentre: boolean, isQA: boolean } }>()
);

export const SaveUserType = createAction(
  '[StartPageComponent] Save User Type',
  props<{ isAdmin: boolean, isQA: boolean }>()
);

export const SubmitAccessRequest = createAction(
  '[SummaryPageComponent] Submit Access Request',
  props<{ commentaire: string }>()
);

export const SubmitAccessRequestSuccess = createAction(
  '[@Effect submitAccessRequest$] Submit Access Request Success'
);

export const SubmitAccessRequestFailure = createAction(
  '[@Effect submitAccessRequest$] Submit Access Request Failure',
  props<{ error: BnaError }>()
);
