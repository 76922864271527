<div class="top-row">
  <div class="department-control">
    <mat-form-field class="department-select">
      <mat-label>{{ config.centreSearchDepartment.label! }}</mat-label>
      <mat-select [formControl]="departmentCtrl">
        <mat-option *ngFor="let item of departmentList.items"
                    [value]="item.uniqueKey">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="keyword-control">
    <mat-form-field class="keyword-input">
      <mat-label>{{ config.centreSearchKeyword.label! }}</mat-label>
      <input type="text" matInput [formControl]="keywordCtrl">
      <access-request-tooltip-icon matSuffix [tooltip]="config.centreSearchKeyword.tooltip!" class="tooltip-icon"/>
    </mat-form-field>
  </div>
</div>
<div class="filtered-centres-list">
  <ng-container *ngIf="(filteredCentres ?? []).length > 0">
    <mat-selection-list [formControl]="selectedCentreCtrl"
                        [multiple]="false">
      <mat-list-option *ngFor="let centre of filteredCentres" [value]="centre.id">
        {{ centre.nom }}
      </mat-list-option>
    </mat-selection-list>
  </ng-container>
  <p *ngIf="filteredCentres?.length === 0">Aucun résultat.</p>
</div>
