<ng-container *ngIf="textConfig$ | async as config">
  <div class="title">
    <h1>{{ config.title }}</h1>
  </div>
  <p>{{ config.description }}</p>
  <div class="user-type-form">
    <mat-form-field *ngIf="userTypeList$ | async as userTypeList">
      <mat-label>{{ config.userType.label! }}</mat-label>
      <mat-select [formControl]="userTypeCtrl">
        <mat-option *ngFor="let item of userTypeList.items" [value]="item">{{ item.label }}</mat-option>
      </mat-select>
      <access-request-tooltip-icon matSuffix [tooltip]="config.userType.tooltip!" class="tooltip-icon"/>
    </mat-form-field>
    <div class="centre-exists" *ngIf="showCentreExistsCtrl$ | async">
      <span>{{ config.centreExists.label! }}</span>
      <mat-radio-group [formControl]="centreExistsCtrl">
        <mat-radio-button [value]="true">Est déjà enregistré dans la BNA</mat-radio-button>
        <mat-radio-button [value]="false">N'est pas enregistré dans la BNA</mat-radio-button>
      </mat-radio-group>
      <access-request-tooltip-icon [tooltip]="config.centreExists.tooltip!" class="vertical-centered-tooltip"/>
    </div>
  </div>
  <access-request-centre-creation *ngIf="showCentreCreation$ | async"
                                  (openTooltip)="onOpenTooltipModal($event)"
                                  [config]="config"/>
  <access-request-centre-selection *ngIf="showCentreSelection$ | async"
                                   (openTooltip)="onOpenTooltipModal($event)"
                                   [config]="config"/>
  <access-request-ars-selection *ngIf="showArsSelection$ | async"
                                (openTooltip)="onOpenTooltipModal($event)"
                                [config]="config"/>

  <div class="checkboxes-container" *ngIf="showCheckboxes$ | async">
    <div class="checkboxes">
      <mat-checkbox [formControl]="isAdminCtrl">{{ config.otherAdminLabel }}</mat-checkbox>
      <mat-checkbox [formControl]="isQACtrl">{{ config.otherQALabel }}</mat-checkbox>
    </div>
    <access-request-tooltip-icon (click)="onOpenTooltipModal(config.otherUserRolesTooltip)"
                                 tooltip="Cliquez pour plus d'informations"
                                 class="vertical-centered-tooltip" />
  </div>

  <button mat-flat-button
          color="primary"
          *ngIf="showBasicContinueButton$ | async"
          class="continue-button"
          (click)="onContinue()">{{ config.firstPageContinueButtonText }}</button>
</ng-container>

