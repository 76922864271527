import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PersonalInfo } from '@access-request-unicorn/models/personal-info.model';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { Store } from '@ngrx/store';
import { AccessRequest } from '@access-request-unicorn/models/access-request.model';
import { selectAccessRequest } from '@access-request-unicorn/state/app.selectors';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';

@Component({
  selector: 'access-request-summary-personal-info',
  templateUrl: './summary-personal-info.component.html',
  styleUrls: ['./summary-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryPersonalInfoComponent implements OnInit {
  accessRequest$!: Observable<AccessRequest>;
  personalInfo$!: Observable<PersonalInfo>;
  config$!: Observable<DisplayTextConfig>;

  constructor(private store: Store,
              private config: ConfigFacade) {}

  ngOnInit() {
    this.config$ = this.config.displayText$;
    this.accessRequest$ = this.store.select(selectAccessRequest).pipe(
      shareReplay(1)
    );
    this.personalInfo$ = this.accessRequest$.pipe(
      filter(request => !!request),
      map(request => request.personalInfo)
    );
  }
}
