<ng-container *ngIf="config$ | async as config">
  <mat-card *ngIf="newCentreRequest$ | async as newCentreRequest">
    <mat-card-header>
      <h1 matCardTitle>{{ config.summaryPage.newCentreRequestCardTitle }}</h1>
    </mat-card-header>
    <mat-card-content>
      <div class="summary-container">
        <div class="summary-item full-width" *ngIf="centreArsLabel$ | async as centreArsLabel">
          <div class="summary-label">ARS :</div>
          <div class="summary-value">{{ centreArsLabel }}</div>
        </div>
        <div class="summary-item full-width" *ngIf="parentCentreLabel$ | async as parentCentreLabel">
          <div class="summary-label">Centre parent :</div>
          <div class="summary-value">{{ parentCentreLabel }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="newCentreRequest.finessJuridique">
          <div class="summary-label">Finess Juridique :</div>
          <div class="summary-value">{{ newCentreRequest.finessJuridique }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="newCentreRequest.finessGeographique">
          <div class="summary-label">Finess Géographique :</div>
          <div class="summary-value">{{ newCentreRequest.finessGeographique }}</div>
        </div>
        <div class="summary-item full-width" *ngIf="newCentreRequest.numAdeli">
          <div class="summary-label">Numéro ADELI :</div>
          <div class="summary-value">{{ newCentreRequest.numAdeli }}</div>
        </div>
        <div class="summary-item full-width" *ngIf="applicationLabel$ | async as applicationLabel">
          <div class="summary-label">Application :</div>
          <div class="summary-value">{{ applicationLabel }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="centreLabelLabel$ | async as centreLabelLabel">
          <div class="summary-label">Centre labelisé :</div>
          <div class="summary-value">{{ centreLabelLabel }}</div>
        </div>
        <div class="summary-item half-width" *ngIf="centreLabelLabel$ | async">
          <div class="summary-label">Année de labelisation :</div>
          <div class="summary-value">{{ newCentreRequest.centreLabelAnnee ?? '' }}</div>
        </div>
        <div class="summary-item full-width">
          <div class="summary-label">Nom du centre :</div>
          <div class="summary-value">{{ newCentreRequest.nomCentre }}</div>
        </div>
        <div class="summary-item full-width">
          <div class="summary-label">Adresse :</div>
          <div class="summary-value">{{ newCentreRequest.adRue1 }}</div>
        </div>
        <div class="summary-item full-width" *ngIf="newCentreRequest.adRue2">
          <div class="summary-label">Adresse (suite) :</div>
          <div class="summary-value">{{ newCentreRequest.adRue2 }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Code postal :</div>
          <div class="summary-value">{{ newCentreRequest.adCp }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Ville :</div>
          <div class="summary-value">{{ newCentreRequest.adVille }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Numéro de téléphone :</div>
          <div class="summary-value">{{ newCentreRequest.telephone }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Adresse mail :</div>
          <div class="summary-value">{{ newCentreRequest.mail }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
