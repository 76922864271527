<mat-card *ngIf="textConfig$ | async as config">
  <mat-card-header>
    <h1 matCardTitle>Informations personnelles</h1>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="personalInfoForm">
      <div class="form-row">
        <div class="form-field">
          <mat-form-field>
            <mat-label>Nom</mat-label>
            <input type="text" matInput formControlName="nom">
            <mat-error *ngIf="nomCtrlHasErrors$ | async">{{ config.personalInfo.nom.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-label>Prénom</mat-label>
            <input type="text" matInput formControlName="prenom">
            <mat-error *ngIf="prenomCtrlHasErrors$ | async">{{ config.personalInfo.prenom.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
          <mat-form-field>
            <mat-label>{{ config.personalInfo.telFixe.label! }}</mat-label>
            <input type="text" matInput formControlName="telFixe">
            <access-request-tooltip-icon matSuffix [tooltip]="config.personalInfo.telFixe.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error *ngIf="telFixeCtrlHasErrors$ | async">{{ config.personalInfo.telFixe.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-label>{{ config.personalInfo.telMobile.label! }}</mat-label>
            <input type="text" matInput formControlName="telMobile">
            <access-request-tooltip-icon matSuffix [tooltip]="config.personalInfo.telMobile.tooltip!"
                                         class="tooltip-icon"/>
            <mat-error
              *ngIf="telMobileCtrlHasErrors$ | async">{{ config.personalInfo.telMobile.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="mail-input-field">
          <mat-form-field>
            <mat-label>{{ config.personalInfo.mail.label! }}</mat-label>
            <input type="text" matInput formControlName="mail">
            <access-request-tooltip-icon matSuffix [tooltip]="config.personalInfo.mail.tooltip!" class="tooltip-icon"/>
            <mat-error *ngIf="mailCtrlHasErrors$ | async">{{ config.personalInfo.mail.errorMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="centered">
        <mat-checkbox formControlName="charteAcceptee">{{ config.personalInfo.charteCheckboxLabel }}</mat-checkbox>
        <a mat-button
           href="assets/charte.pdf"
           target="_blank"
           color="accent"
           type="button">{{ config.personalInfo.consultChartButtonLabel }}</a>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="onBack()">RETOUR</button>
    <button mat-flat-button color="primary"
            (click)="onContinue()"
            [disabled]="(disableButton$ | async) || false">{{ config.personalInfo.continueButtonText | uppercase }}</button>
  </mat-card-actions>
</mat-card>
