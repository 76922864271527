export class GetAccessRequestDto {
  DemandeArsNom!: string;
  DemandeCentreNom!: string;
  DemandeEtatLabel!: string;
  CentreArsNom!: string;
  CentreParentNom!: string;
  CentreApplicationLabel!: string;
  CentreLabelLabel!: string;
  ID!: string;
  CentreAdCp!: string;
  CentreAdRue1!: string;
  CentreAdRue2!: string;
  CentreAdVille!: string;
  CentreApplication!: string;
  CentreArs!: string;
  CentreLabel!: string;
  CentreLabelAnnee!: string;
  CentreMail!: string;
  CentreNom!: string;
  CentreNumAdeli!: string;
  CentreNumFinessGeo!: string;
  CentreNumFinessJuridique!: string;
  CentreParent!: string;
  CentreTel!: string;
  DemandeArs!: string;
  DemandeCentre!: string;
  DemandeCommentaire!: string;
  DemandeDate!: number;
  DemandeFormattedDate!: string;
  DemandeEtat!: string;
  DemandeValidationCode!: number;
  UtilisateurIsAdmin!: boolean;
  UtilisateurIsQuality!: boolean;
  UtilisateurIsSupervisor!: boolean;
  UtilisateurMail!: string;
  UtilisateurNom!: string;
  UtilisateurPrenom!: string;
  UtilisateurTelFixe!: string;
  UtilisateurTelMobile!: string;
  UtilisateurType!: string;
  UtilisateurTypeLabel!: string;
  utilisateurTypeLabel?: string; // TODO: remove when backend corrected
}
