import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ellipse-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnInit {
  @Input() iconName!: string;
  @Input() iconSize?: string | number;

  concatenatedClasses!: string;

  ngOnInit() {
    this.concatenatedClasses = `${this.iconName} fa-${this.iconSize}`;
  }
}
