<ng-container *ngIf="config$ | async as config">
  <mat-card *ngIf="personalInfo$ | async as personalInfo">
    <mat-card-header>
      <h1 matCardTitle>{{ config.summaryPage.personalInfoCardTitle }}</h1>
    </mat-card-header>
    <mat-card-content>
      <div class="summary-container">
        <div class="summary-item half-width">
          <div class="summary-label">Nom :</div>
          <div class="summary-value">{{ personalInfo.nom | uppercase }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Prénom :</div>
          <div class="summary-value">{{ personalInfo.prenom }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Téléphone fixe :</div>
          <div class="summary-value">{{ personalInfo.telFixe }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Téléphone mobile :</div>
          <div class="summary-value">{{ personalInfo.telMobile }}</div>
        </div>
        <div class="summary-item half-width">
          <div class="summary-label">Adresse mail :</div>
          <div class="summary-value">{{ personalInfo.mail }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
