import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { List } from 'types-unicorn';
import { selectList } from '@access-request-unicorn/state/lists/lists.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListsFacade {
  constructor(private store: Store) {}

  getListByUniqueKey(uniqueKey: string): Observable<List> {
    return this.store.select(selectList(uniqueKey));
  }
}
