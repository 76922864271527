import { isEqual } from 'lodash';

export class PersonalInfo {
  nom!: string;
  prenom!: string;
  telFixe!: string;
  telMobile!: string;
  mail!: string;

  static nullPersonalInfo(): PersonalInfo {
    return {
      nom: '',
      prenom: '',
      telFixe: '',
      telMobile: '',
      mail: '',
    };
  }

  static isNullPersonalInfo(personalInfo: PersonalInfo): boolean {
    return isEqual(personalInfo, PersonalInfo.nullPersonalInfo());
  }
}
