import { ServerError } from './server-error.model';

export class BnaError {
  title?: string;
  message!: string;
  subErrors?: string[];

  static nullError(): BnaError {
    return { title: '', message: '', subErrors: [] };
  }

  static isNullError(error: BnaError): boolean {
    return error.title === '' && error.message === '';
  }

  static fromServerError(response: ServerError): BnaError {
    return response.status === 500 ? this.unknownError() : this.mapServerErrorToBnaError(response);
  }

  private static mapServerErrorToBnaError(response: ServerError) {
    return {
      title: response.error.errorContext,
      message: response.error.errorMessage,
      subErrors: this.parseSubErrors(response)
    };
  }

  private static parseSubErrors(response: ServerError) {
    return response.error.errors.map(error => error.errorItem ?
      `${ error.errorItem } - ${ error.errorMessage }` :
      error.errorMessage
    );
  }

  private static unknownError(): BnaError {
    return {
      title: 'Erreur serveur',
      message: `Une erreur est survenue, merci de renouveler votre demande. Si cette erreur persiste, veuillez contacter votre administrateur.`
    }
  }
}
