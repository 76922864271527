import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ellipse-access-denied-dialog',
  templateUrl: './access-denied-dialog.component.html',
  styleUrl: './access-denied-dialog.component.scss'
})
export class AccessDeniedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageUrl: string }) {
  }
}
