import { createAction, props } from '@ngrx/store';
import { AppConfig } from '@access-request-unicorn/models/app-config.model';
import { BnaError } from 'types-unicorn';

export const GetAppConfigSuccess = createAction(
  '[@Effect getAppConfig$] Get App Config Success',
  props<{ appConfig: AppConfig }>()
);

export const GetAppConfigFailure = createAction(
  '[@Effect getAppConfig$] Get App Config Failure',
  props<{ error: BnaError }>()
);
