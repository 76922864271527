<ng-container *ngIf="config$ | async as config">
  <h2>Résumé de la demande</h2>
  <access-request-summary-personal-info />
  <access-request-summary-user-info />
  <access-request-summary-new-centre-request *ngIf="newCentreRequest$ | async"/>
  <mat-form-field class="full-width-form-field">
    <mat-label>{{ config.summaryPage.commentFieldLabel }}</mat-label>
    <textarea rows="10" matInput [formControl]="commentCtrl"></textarea>
  </mat-form-field>
  <div class="buttons">
    <button mat-stroked-button
            (click)="onBack()">
      RETOUR
    </button>
    <button mat-flat-button
            color="primary"
            (click)="onContinue()">
      {{ config.summaryPage.submitButtonLabel }}
    </button>
  </div>
</ng-container>
