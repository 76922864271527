import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TooltipModalComponent
} from '@access-request-unicorn/components/standalone/tooltip-modal/tooltip-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TooltipModalService {

  constructor(private dialog: MatDialog) {}

  openDialogWithTooltip(tooltip: string) {
    this.dialog.open(TooltipModalComponent, {
      width: '50%',
      data: { tooltip }
    });
  }
}
