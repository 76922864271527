import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { AccessRequest } from '@access-request-unicorn/models/access-request.model';
import { selectAccessRequest } from '@access-request-unicorn/state/app.selectors';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { CentreRequest } from '@access-request-unicorn/models/centre-request.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { SubmitAccessRequest } from '@access-request-unicorn/state/app.actions';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';
import { Location } from '@angular/common';

@Component({
  selector: 'access-request-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryPageComponent implements OnInit {

  accessRequest$!: Observable<AccessRequest>;
  config$!: Observable<DisplayTextConfig>;
  newCentreRequest$!: Observable<CentreRequest>;

  commentCtrl!: FormControl;

  constructor(private store: Store,
              private formBuilder: FormBuilder,
              private config: ConfigFacade,
              private location: Location) {}

  ngOnInit(): void {
    this.buildCommentControl();
    this.connectStoreObservables();
  }

  private buildCommentControl() {
    this.commentCtrl = this.formBuilder.control('');
  }

  private connectStoreObservables() {
    this.accessRequest$ = this.store.select(selectAccessRequest).pipe(
      shareReplay(1)
    );
    this.newCentreRequest$ = this.accessRequest$.pipe(
      filter(accessRequest => !!accessRequest.newCentreRequest),
      map(accessRequest => accessRequest.newCentreRequest!),
      shareReplay(1)
    );
    this.config$ = this.config.displayText$;
  }

  onContinue() {
    this.store.dispatch(SubmitAccessRequest({ commentaire: this.commentCtrl.value }));
  }

  onBack() {
    this.location.back();
  }
}
