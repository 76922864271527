import { createAction, props } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { Ars } from '@access-request-unicorn/models/ars.model';
import { BnaError, List } from 'types-unicorn';

export const GetAppListsSuccess = createAction(
  '[@Effect getAppLists$] Get App Lists Success',
  props<{ lists: Dictionary<List> }>()
);

export const GetAppListsFailure = createAction(
  '[@Effect getAppLists$] Get App Lists Failure',
  props<{ error: BnaError }>()
);

export const GetArsListSuccess = createAction(
  '[@Effect getArsList$] Get Ars List Success',
  props<{ arsList: Ars[] }>()
);

export const GetArsListFailure = createAction(
  '[@Effect getArsList$] Get Ars List Failure',
  props<{ error: BnaError }>()
);
