import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { DataService } from '@access-request-unicorn/services/data.service';
import * as AppActions from './app.actions';
import * as ConfigActions from './config/config.actions';
import * as ListsActions from './lists/lists.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectAccessRequestDto, selectUserCentreTypeId } from '@access-request-unicorn/state/app.selectors';
import { BnaError } from '../../../../types-unicorn/src/lib/errors';
import { DialogUnicornService } from '../../../../dialog-unicorn/src/lib/dialog-unicorn.service';

@Injectable()
export class AppEffects {

  findCentres$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.FindCentres),
      concatLatestFrom(() => this.store.select(selectUserCentreTypeId)),
      switchMap(([{ query: { department, keyword } }, userCentreType]) => this.app.findCentres(department, keyword, userCentreType).pipe(
        map(centres => AppActions.FindCentresSuccess({ centres })),
        catchError(error => of(AppActions.FindCentresFailure({ error })))
      ))
    );
  });

  findAllCentres$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.FindParentCentres),
      switchMap(({ query: { department, keyword } }) => this.app.findCentres(department, keyword).pipe(
        map(centres => AppActions.FindCentresSuccess({ centres })),
        catchError(error => of(AppActions.FindCentresFailure({ error })))
      ))
    );
  });

  moveToPersonalInfoPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AppActions.SaveSelectedCentre,
        AppActions.SaveUserArs,
        AppActions.SaveCentreRequest,
        AppActions.SaveUserType,
      ),
      tap(() => this.router.navigate(['access-request', 'personal-info'])),
    );
  }, { dispatch: false });

  moveToSummaryPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.SaveUserPersonalInfo),
      tap(() => this.router.navigate(['access-request', 'summary']))
    );
  }, { dispatch: false });

  submitAccessRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.SubmitAccessRequest),
      switchMap(() => this.store.select(selectAccessRequestDto).pipe(take(1))),
      switchMap(dto => this.app.submitAccessRequest(dto).pipe(
        map(() => AppActions.SubmitAccessRequestSuccess()),
        catchError((error: BnaError) => of(AppActions.SubmitAccessRequestFailure({ error })))
      ))
    )
  });

  moveToConfirmationPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.SubmitAccessRequestSuccess),
      tap(() => this.router.navigate(['access-request', 'confirmation']))
    );
  }, { dispatch: false })

  showErrorDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ConfigActions.GetAppConfigFailure,
        ListsActions.GetAppListsFailure,
        ListsActions.GetArsListFailure,
        AppActions.FindCentresFailure,
        AppActions.SubmitAccessRequestFailure
      ),
      tap(({ error }) => this.dialog.showErrorDialog({ error }))
    );
  }, { dispatch: false });

  constructor(private actions$: Actions,
              private app: DataService,
              private store: Store,
              private router: Router,
              private dialog: DialogUnicornService) {}
}
