import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, Observable, shareReplay, startWith, Subject, take, tap } from 'rxjs';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  selectFindCentreFormData,
  selectIsCentreNonLiberalUser,
  selectUserCentreIsLiberal,
  selectUserCentreTypeLabel
} from '@access-request-unicorn/state/app.selectors';
import {
  ClearUserCentre,
  FindParentCentres,
  SaveCentreRequest,
  SetParentCentre
} from '@access-request-unicorn/state/app.actions';
import { List } from 'types-unicorn';
import { Ars } from '@access-request-unicorn/models/ars.model';
import { FindCentreFormData } from '@access-request-unicorn/models/find-centre-form-data.model';
import { FindCentreQuery } from '@access-request-unicorn/models/find-centre-query.model';
import { CentreLabelYearValidator } from '@access-request-unicorn/validators/centre-label-year.validator';
import { listsFeature } from '@access-request-unicorn/state/lists/lists.reducer';
import { ListsFacade } from '@access-request-unicorn/services/lists.facade';

@Component({
  selector: 'access-request-centre-creation',
  templateUrl: './centre-creation.component.html',
  styleUrls: ['./centre-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentreCreationComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() config!: DisplayTextConfig;
  @Output() openTooltip = new EventEmitter<string>();
  isCentreNonLiberal$!: Observable<boolean>;
  isCentreLiberal$!: Observable<boolean>;
  applicationsList$!: Observable<List>;
  arsList$!: Observable<Ars[]>;
  centreTypeLabel$!: Observable<string>;
  hasParentCentre$!: Observable<boolean>;
  doesNotHaveParentCentre$!: Observable<boolean>;
  findCentreFormData$!: Observable<FindCentreFormData>;
  labelisationChoiceList$!: Observable<List>;

  hasParentCentreCtrl!: FormControl;
  applicationIdCtrl!: FormControl;
  nomCentreCtrl!: FormControl;
  adRue1Ctrl!: FormControl;
  adRue2Ctrl!: FormControl;
  adCpCtrl!: FormControl;
  adVilleCtrl!: FormControl;
  telephoneCtrl!: FormControl
  mailCtrl!: FormControl;
  isQACtrl!: FormControl;

  finessJuridiqueCtrl!: FormControl;
  finessGeographiqueCtrl!: FormControl;
  numAdeliCtrl!: FormControl;
  centreLabelCtrl!: FormControl;
  centreLabelAnneeCtrl!: FormControl;

  arsIdCtrl!: FormControl
  centreParentIdCtrl!: FormControl;

  showLabelisationYearCtrl$!: Observable<boolean>;

  applicationCtrlHasError$!: Observable<boolean>;
  centreNameCtrlHasError$!: Observable<boolean>;
  centreAddressCtrlHasError$!: Observable<boolean>;
  centreZipCodeCtrlHasError$!: Observable<boolean>;
  centreTownCodeCtrlHasError$!: Observable<boolean>;
  centrePhoneCtrlHasError$!: Observable<boolean>;
  centreMailCtrlHasError$!: Observable<boolean>;

  finessJuridiqueCtrlHasError$!: Observable<boolean>;
  finessGeographiqueCtrlHasError$!: Observable<boolean>;

  numAdeliCtrlHasError$!: Observable<boolean>;

  centreLabelAnneeCtrlHasError$!: Observable<boolean>;

  centreForm!: FormGroup;
  private destroy$!: Subject<boolean>;

  constructor(private store: Store,
              private formBuilder: FormBuilder,
              private centreLabelYearValidator: CentreLabelYearValidator,
              private lists: ListsFacade) {
  }

  ngOnInit() {
    this.destroy$ = new Subject<boolean>();
    this.clearUserCentre();
    this.initForm();
    this.connectFormListeners();
    this.connectStoreObservables();
  }

  ngAfterViewInit() {
    this.patchCentreDetailsIfAlreadySet();
  }

  onContinue() {
    this.store.dispatch(SaveCentreRequest({ centreDetails: this.centreForm.value }));
  }

  onSelectParentCentre(centreId: number) {
    this.centreParentIdCtrl.patchValue(centreId);
    this.store.dispatch(SetParentCentre({ centreId }));
  }

  onSearch(query: FindCentreQuery) {
    this.store.dispatch(FindParentCentres({ query }));
  }

  onOpenTooltip(tooltip: string) {
    this.openTooltip.next(tooltip);
  }

  private connectStoreObservables() {
    this.findCentreFormData$ = this.store.select(selectFindCentreFormData({ selectedParentCentre: true }));
    this.isCentreNonLiberal$ = this.store.select(selectIsCentreNonLiberalUser).pipe(
      tap(isCentreNonLiberal => {
        if (isCentreNonLiberal) {
          this.setFormControlsForCentreNonLiberal();
        }
      }),
      shareReplay(1)
    );
    this.isCentreLiberal$ = this.store.select(selectUserCentreIsLiberal).pipe(
      tap(isCentreLiberal => {
        if (isCentreLiberal) {
          this.setFormControlsForCl();
        }
      }),
      shareReplay(1)
    );
    this.centreTypeLabel$ = this.store.select(selectUserCentreTypeLabel);
    this.applicationsList$ = this.lists.getListByUniqueKey('ApplicationSaisie');
    this.labelisationChoiceList$ = this.lists.getListByUniqueKey('CentreLabel');
    this.arsList$ = this.store.select(listsFeature.selectArsList);
  }

  private patchCentreDetailsIfAlreadySet() {
    this.store.select(appFeature.selectCentreDetails).pipe(
      take(1),
      filter(details => !!details),
      tap(details => this.centreForm.patchValue(details!))
    ).subscribe();
    this.store.select(appFeature.selectUserIsQA).pipe(
      take(1),
      tap(isQA => this.isQACtrl.patchValue(isQA))
    );
  }

  private setFormControlsForCl() {
    this.centreForm.removeControl('finessJuridique');
    this.centreForm.removeControl('finessGeographique');
    this.centreForm.removeControl('centreLabel');
    this.centreForm.removeControl('centreLabelAnnee');
    this.centreForm.addControl('numAdeli', this.numAdeliCtrl);
    this.centreForm.updateValueAndValidity();
  }

  private setFormControlsForCentreNonLiberal() {
    this.centreForm.removeControl('numAdeli');
    this.centreForm.addControl('finessJuridique', this.finessJuridiqueCtrl);
    this.centreForm.addControl('finessGeographique', this.finessGeographiqueCtrl);
    this.centreForm.addControl('centreLabel', this.centreLabelCtrl);
    this.centreForm.addControl('centreLabelAnnee', this.centreLabelAnneeCtrl);
    this.centreForm.updateValueAndValidity();
  }

  private initForm() {
    this.initCommonControls();
    this.buildFormGroup();
    this.initConditionalControls();
  }

  private buildFormGroup() {
    this.centreForm = this.formBuilder.group({
      hasParentCentre: this.hasParentCentreCtrl,
      applicationId: this.applicationIdCtrl,
      nomCentre: this.nomCentreCtrl,
      adRue1: this.adRue1Ctrl,
      adRue2: this.adRue2Ctrl,
      adCp: this.adCpCtrl,
      adVille: this.adVilleCtrl,
      telephone: this.telephoneCtrl,
      mail: this.mailCtrl,
      isQA: this.isQACtrl
    });
  }

  private initCommonControls() {
    this.hasParentCentreCtrl = this.formBuilder.control(false);
    this.applicationIdCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.nomCentreCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.adRue1Ctrl = this.formBuilder.control('', { validators: Validators.required });
    this.adRue2Ctrl = this.formBuilder.control('');
    this.adCpCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.pattern(/^[0-9][0-9A-B][0-9]{3}$/)] });
    this.adVilleCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.telephoneCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.mailCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.email] });
    this.isQACtrl = this.formBuilder.control(false);
  }

  private connectFormListeners() {
    this.handleParentCentreValueChanges();
    this.handleLabelisationValueChanges();
    this.connectErrorListeners();
  }

  private initConditionalControls() {
    this.arsIdCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.centreParentIdCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.centreLabelCtrl = this.formBuilder.control(null, { validators: [Validators.required] });
    this.centreLabelAnneeCtrl = this.formBuilder.control('', {
      validators: [Validators.required],
      asyncValidators: [this.centreLabelYearValidator.validate.bind(this.centreLabelYearValidator)]
    });
    this.finessJuridiqueCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.pattern(/^[0-9]{9}$/)] });
    this.finessGeographiqueCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.pattern(/^[0-9]{9}$/)] });
    this.numAdeliCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.pattern(/[0-9]{9}/)] });
  }

  private handleParentCentreValueChanges() {
    this.hasParentCentre$ = this.hasParentCentreCtrl.valueChanges.pipe(
      startWith(this.hasParentCentreCtrl.value),
      tap(hasParentCentre => hasParentCentre ?
        this.setFormControlsForParentCentre() :
        this.setFormControlsForArs()
      ),
      shareReplay(1)
    );
    this.doesNotHaveParentCentre$ = this.hasParentCentre$.pipe(
      map(hasParentCentre => !hasParentCentre)
    );
  }

  private handleLabelisationValueChanges() {
    this.showLabelisationYearCtrl$ = this.centreLabelCtrl.valueChanges.pipe(
      map(value => value === '335'), // 335 correpond à la valeur "OUI"
      distinctUntilChanged(),
      tap(showCtrl => this.addOrRemoveCentreLabelAnneeCtrl(showCtrl))
    );
  }

  private addOrRemoveCentreLabelAnneeCtrl(showCtrl: boolean) {
    if (showCtrl) {
      this.centreForm.addControl('centreLabelAnnee', this.centreLabelAnneeCtrl);
    } else {
      this.centreForm.removeControl('centreLabelAnnee');
    }
    this.centreForm.updateValueAndValidity();
  }

  private clearUserCentre() {
    this.store.dispatch(ClearUserCentre());
  }

  private setFormControlsForParentCentre() {
    this.centreForm.removeControl('arsId');
    this.centreForm.addControl('centreParentId', this.centreParentIdCtrl);
    this.centreForm.updateValueAndValidity();
  }

  private setFormControlsForArs() {
    this.centreForm.removeControl('centreParentId');
    this.centreForm.addControl('arsId', this.arsIdCtrl);
    this.centreForm.updateValueAndValidity();
  }

  private connectErrorListeners() {
    this.applicationCtrlHasError$ = this.getControlIsInvalid(this.applicationIdCtrl);
    this.centreNameCtrlHasError$ = this.getControlIsInvalid(this.nomCentreCtrl);
    this.centreAddressCtrlHasError$ = this.getControlIsInvalid(this.adRue1Ctrl);
    this.centreZipCodeCtrlHasError$ = this.getControlIsInvalid(this.adCpCtrl);
    this.centreTownCodeCtrlHasError$ = this.getControlIsInvalid(this.adVilleCtrl);
    this.centrePhoneCtrlHasError$ = this.getControlIsInvalid(this.telephoneCtrl);
    this.centreMailCtrlHasError$ = this.getControlIsInvalid(this.mailCtrl);
    this.finessJuridiqueCtrlHasError$ = this.getControlIsInvalid(this.finessJuridiqueCtrl);
    this.finessGeographiqueCtrlHasError$ = this.getControlIsInvalid(this.finessGeographiqueCtrl);
    this.numAdeliCtrlHasError$ = this.getControlIsInvalid(this.numAdeliCtrl);
    this.centreLabelAnneeCtrlHasError$ = this.getControlIsInvalid(this.centreLabelAnneeCtrl);
  }

  private getControlIsInvalid(ctrl: AbstractControl): Observable<boolean> {
    return ctrl.statusChanges.pipe(
      map(status => status === 'INVALID')
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
