<div class="ars-form-container">
  <mat-form-field>
    <mat-label>Mon ARS</mat-label>
    <mat-select *ngIf="arsList$ | async as arsList" [formControl]="arsCtrl">
      <mat-option *ngFor="let ars of arsList" [value]="ars.id">{{ ars.nom }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="checkboxes-container" *ngIf="showCheckboxes$ | async">
    <div class="checkboxes">
      <mat-checkbox [formControl]="isAdminCtrl">{{ config.arsAdminLabel }}</mat-checkbox>
      <mat-checkbox [formControl]="isQACtrl">{{ config.arsQALabel }}</mat-checkbox>
    </div>
    <access-request-tooltip-icon (click)="onOpenTooltip(config.arsUserRolesTooltip)"
                                 tooltip="Cliquez pour plus d'informations"
                                 class="vertical-centered-tooltip" />
  </div>
  <div class="continue-button-container" *ngIf="showCheckboxes$ | async">
    <button mat-flat-button
            color="primary"
            (click)="onContinue()">{{ config.firstPageContinueButtonText }}</button>
  </div>
</div>
