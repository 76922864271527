import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, startWith, Subject, take, takeUntil, tap } from 'rxjs';
import { appFeature } from '@access-request-unicorn/state/app.reducer';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { SaveUserPersonalInfo } from '@access-request-unicorn/state/app.actions';
import { PersonalInfo } from '@access-request-unicorn/models/personal-info.model';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';
import { PHONE_REGEX } from '@access-request-unicorn/regex/phone.regex';

@Component({
  selector: 'access-request-personal-info-page',
  templateUrl: './personal-info-page.component.html',
  styleUrls: ['./personal-info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalInfoPageComponent implements OnInit {

  textConfig$!: Observable<DisplayTextConfig>;
  disableButton$!: Observable<boolean>;
  personalInfoForm!: FormGroup;

  nomCtrl!: FormControl;
  prenomCtrl!: FormControl;
  telFixeCtrl!: FormControl;
  telMobileCtrl!: FormControl;
  mailCtrl!: FormControl;
  charteAccepteeCtrl!: FormControl;

  nomCtrlHasErrors$!: Observable<boolean>;
  prenomCtrlHasErrors$!: Observable<boolean>;
  telFixeCtrlHasErrors$!: Observable<boolean>;
  telMobileCtrlHasErrors$!: Observable<boolean>;
  mailCtrlHasErrors$!: Observable<boolean>;

  private destroy$!: Subject<boolean>;

  constructor(private store: Store,
              private formBuilder: FormBuilder,
              private location: Location,
              private config: ConfigFacade) {}

  ngOnInit(): void {
    this.destroy$ = new Subject<boolean>();
    this.initForm();
    this.connectStoreObservables();
    this.connectFormListeners();
    this.connectErrorListeners();
  }

  private connectStoreObservables() {
    this.textConfig$ = this.config.displayText$;
    this.patchFormIfValuesAlreadySet();
  }

  private patchFormIfValuesAlreadySet() {
    this.store.select(appFeature.selectPersonalInfo).pipe(
      take(1),
      filter(personalInfo => !PersonalInfo.isNullPersonalInfo(personalInfo)),
      tap(personalInfo => this.personalInfoForm.patchValue(personalInfo))
    ).subscribe();
  }

  private initForm() {
    this.nomCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.prenomCtrl = this.formBuilder.control('', { validators: [Validators.required] });
    this.telFixeCtrl = this.formBuilder.control('', {
      validators: [
        Validators.required,
        Validators.pattern(PHONE_REGEX)
      ]
    });
    this.telMobileCtrl = this.formBuilder.control('', {
      validators: [
        Validators.required,
        Validators.pattern(PHONE_REGEX)
      ]
    });
    this.mailCtrl = this.formBuilder.control('', { validators: [Validators.required, Validators.email] });
    this.charteAccepteeCtrl = this.formBuilder.control(false, { validators: [Validators.requiredTrue] })
    this.personalInfoForm = this.formBuilder.group({
      nom: this.nomCtrl,
      prenom: this.prenomCtrl,
      telFixe: this.telFixeCtrl,
      telMobile: this.telMobileCtrl,
      mail: this.mailCtrl,
      charteAcceptee: this.charteAccepteeCtrl
    });
  }

  private connectFormListeners() {
    this.disableButton$ = this.personalInfoForm.statusChanges.pipe(
      startWith(this.personalInfoForm.status),
      map(status => status !== 'VALID'),
    );
    this.nomCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((value: string) => this.nomCtrl.patchValue(value.toLocaleUpperCase(), { emitEvent: false }))
    ).subscribe();
  }

  onContinue() {
    this.store.dispatch(SaveUserPersonalInfo({ personalInfo: this.personalInfoForm.value }));
  }

  onBack() {
    this.location.back();
  }

  private connectErrorListeners() {
    this.nomCtrlHasErrors$ = this.getControlIsInvalid(this.nomCtrl);
    this.prenomCtrlHasErrors$ = this.getControlIsInvalid(this.prenomCtrl);
    this.telFixeCtrlHasErrors$ = this.getControlIsInvalid(this.telFixeCtrl);
    this.telMobileCtrlHasErrors$ = this.getControlIsInvalid(this.telMobileCtrl);
    this.mailCtrlHasErrors$ = this.getControlIsInvalid(this.mailCtrl);
  }

  private getControlIsInvalid(ctrl: AbstractControl): Observable<boolean> {
    return ctrl.statusChanges.pipe(
      map(status => status === 'INVALID')
    );
  }
}
