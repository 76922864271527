import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ErrorDialogData, SuccessDialogData } from './models';
import { AccessDeniedDialogComponent } from './access-denied-dialog/access-denied-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogUnicornService {

  private successDialogRef?: MatDialogRef<SuccessDialogComponent>;
  private errorDialogRef?: MatDialogRef<ErrorDialogComponent>;

  constructor(private dialog: MatDialog) {
  }

  showSuccessDialog(data: SuccessDialogData): void {
    this.successDialogRef = this.dialog.open(SuccessDialogComponent, {
      data,
      width: '60%'
    });
  }

  showErrorDialog(data: ErrorDialogData): void {
    this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
      data,
      width: '60%'
    });
  }

  showAccessDeniedDialog(imageUrl: string): void {
    this.dialog.open(AccessDeniedDialogComponent, {
      data: { imageUrl },
      width: '60%'
    });
  }

  hideSuccessDialog(): void {
    this.successDialogRef?.close();
  }

  hideErrorDialog(): void {
    this.errorDialogRef?.close();
  }
}
