import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { configFeature } from '@access-request-unicorn/state/config/config.reducer';
import { Observable } from 'rxjs';
import { DisplayTextConfig } from '@access-request-unicorn/models/display-text-config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigFacade {
  constructor(private store: Store) {}

  get displayText$(): Observable<DisplayTextConfig> {
    return this.store.select(configFeature.selectDisplayText);
  }

  get keywordMinLengthBeforeCentreRequest$(): Observable<number> {
    return this.store.select(configFeature.selectKeywordMinLengthBeforeCentreRequest);
  }

  get earliestLabelYear$(): Observable<number> {
    return this.store.select(configFeature.selectEarliestLabelYear);
  }
}
