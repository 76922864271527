import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { map, Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigFacade } from '@access-request-unicorn/services/config.facade';

@Injectable({ providedIn: 'root' })
export class CentreLabelYearValidator implements AsyncValidator {
  constructor(private config: ConfigFacade) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.config.earliestLabelYear$.pipe(
      take(1),
      map(earliestLabelYear => {
        const value = parseInt(control.value, 10);
        const thisYear = new Date().getFullYear();
        return Number.isInteger(value) && value >= earliestLabelYear && value <= thisYear ? null : { centreLabelYear: true }
      })
    );
  }
}
