import { ListItem } from './list-item.model';
import { CreateListDto } from './create-list.dto';

export class List {
  id!: number;
  label!: string;
  uniqueKey!: string;
  tooltip!: string;
  items!: ListItem[];
  active!: boolean;
  parentList?: number;

  static empty(): List {
    return {
      id: 0,
      label: '',
      uniqueKey: '',
      tooltip: '',
      items: [],
      active: false
    };
  }

  static newList(): CreateListDto {
    return {
      label: '',
      uniqueKey: '',
      tooltip: '',
      items: [],
      active: false
    }
  }

  static alreadyExists(list: List | CreateListDto): list is List {
    return !!(list as List).id;
  }
}
